import React from "react";
import FlagIcon from "../../../Assets/FlagIconLarger.png";
import BucketEmoji from "../../../Assets/Emojis/DomPerignonBucket.png";
import "../RatingContainers.css";

const FlagsContainer = ({ rating, bucketExperience }) => {
  const ratingRounded = Math.round(rating);
  const flagsArray = new Array(ratingRounded).fill(
    <img src="" alt="star icon" />
  );
  return (
    <div className="RatingContainer">
      <div className="row" title={`GGOG™ Course Rating = ${ratingRounded}`}>
        {flagsArray.map((flag, index) => (
          <div className="col-xs-1" key={index}>
            <img className="RatingIcon" src={FlagIcon} alt="flag rating icon" />
          </div>
        ))}
        {bucketExperience ? (
          <div className="col-xs-1">
            <img
              className="ChampagneBucket"
              src={BucketEmoji}
              alt="Bucket List Course"
              title="One of the world's truly elite courses"
            />
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default FlagsContainer;
