import React from "react";
import "./RegionCard.css";
import CardButton from "../../Buttons/CardButton/CardButton";
import { Link } from "react-router-dom";
import Icon from "../../../Assets/GGOGIconTM.png";
// import FlagIcon from "../../../Assets/FlagIconLarger.png";
import "../Cards.css";

const RegionCard = ({ regionName, destinationName, subtitle, thumbnail, buttonText, id }) => {
  return (
    <Link to={`/destinations/${destinationName}/${regionName}/${id}`}>
    <div className="TravelCard">
      <div className="GGOGIconContainer">
        <img className="GGOGIcon" src={Icon} alt="GGOG Icon"></img>
      </div>
      <div className="CardContentContainer">
        <img
          className="TravelCardThumbnailImage"
          src={thumbnail}
          alt={`${regionName} golf trips and golf vacations`}
        />
        <div className="CardName" id="RegionName">
          <div>{regionName}</div>
          <div id="RegionSubtitle">{subtitle}</div>
        </div>
        <CardButton
          buttonText={buttonText}
        />
      </div>
    </div>
    </Link>
  );
};

export default RegionCard;
