import React from "react";
import { useLoaderData } from "react-router-dom";
import Hero1800 from "../../Assets/GGOGHeroImage1800.webp";
import Hero1280 from "../../Assets/GGOGHeroImage1280.webp";
import Hero800 from "../../Assets/GGOGHeroImage800.webp";
import Logo from "../../Assets/Logo.png";
import DestinationCard from "../../Components/Cards/DestinationCard/DestinationCard";
import HomeText from "../../Components/TextComponents/HomeText/HomeText";
import MainImageButton from "../../Components/Buttons/MainImageButton/MainImageButton";
import SecondaryButton from "../../Components/Buttons/SecondaryButton/SecondaryButton";
import "./Home.css";
import SubscribeForm from "../../Components/SubscribeForm/SubscribeForm";

export const HomeLoader = async () => {
  const res = await fetch("/api/destinations/");
  return res.json();
};

const Home = () => {
  const destinations = useLoaderData();
  const featuredLocations = ["Scotland", "Ireland", "Northern Ireland", "England"];
  const featuredDestinations = destinations.filter((destination) =>
    featuredLocations.includes(destination.destinationName)
  );
  
  return (
    <>
      <div id="Home">
        <div id="HeroImageContainer">
          <img
            id="HeroImage1800"
            src={Hero1800}
            alt="GGOG Great Game of Golf"
          />
          <img
            id="HeroImage1280"
            src={Hero1280}
            alt="GGOG Great Game of Golf"
          />
          <img id="HeroLogo" src={Logo} alt="GGOG Great Game of Golf Logo" />
          <div id="HeroText">
            The Home of Golf Travel<span className="MainImageTMSign">™</span>
          </div>
          <div id="HeroButton">
            <MainImageButton text="Start Planning a Trip" link="/starter" />
          </div>
        </div>
        <div id="HeroImageContainerMobile">
          <img id="HeroImage800" src={Hero800} alt="GGOG Great Game of Golf" />
          <img id="HeroLogo" src={Logo} alt="GGOG Great Game of Golf Logo" />
          <div id="HeroText">
            The Home of Golf Travel<span className="MainImageTMSign">™</span>
          </div>
          <div id="HeroButton">
            <MainImageButton text="Start Planning a Trip" link="/starter" />
          </div>
        </div>
        <div>
          <HomeText />
        </div>
        <div id="HomePageDestinationsHeadline" className="PageSubHeadline">
          Where do you want to{" "}
          <span>
            play?<sup className="PageSubHeadlineTMSign">™</sup>
          </span>
        </div>
        <div id="DestinationCardContainer" className="row">
          {featuredDestinations.map((destination) => (
            <div className="col-xs-1 sm-2 md-3 lg-4" key={destination._id}>
              <DestinationCard
                destinationName={destination.destinationName}
                thumbnail={destination.thumbnail}
                id={destination._id}
                buttonText={`Golf in ${destination.destinationName}`}
              />
            </div>
          ))}
        </div>
        <div className="ViewAllDestinationsButton">
          <SecondaryButton
            text="View All Destinations"
            link="/destinations"
          />
        </div>
      </div>
      <SubscribeForm/>
    </>
  );
};

export default Home;
