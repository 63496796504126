import React, { useState, useEffect } from "react";
import "./RatingInfo.css";
import RatingFlag from "../../Assets/FlagIconLarger.png";
import BucketEmoji from "../../Assets/Emojis/DomPerignonBucket.png";

const RatingInfo = ({ id }) => {
  const [expanded, setExpanded] = useState(() => {
    const ratingInfoExpanded = sessionStorage.getItem("ratingInfoExpanded");
    return JSON.parse(ratingInfoExpanded) ?? [];
  });

  const [viewRatingDescriptions, setViewRatingDescriptions] = useState(() => {
    const ratingInfoExpanded =
      sessionStorage.getItem("ratingInfoExpanded") ?? [];
    return ratingInfoExpanded.includes(id) ? true : false;
  });

  useEffect(() => {
    sessionStorage.setItem("ratingInfoExpanded", JSON.stringify(expanded));
  }, [expanded]);

  const expandRatingInfo = () => {
    const destinationsWithExpandedRatingInfo = [...expanded];
    if (!destinationsWithExpandedRatingInfo.includes(id)) {
      destinationsWithExpandedRatingInfo.push(id);
    }
    setExpanded(destinationsWithExpandedRatingInfo);
    setViewRatingDescriptions(true);
    sessionStorage.setItem("ratingInfoExpanded", JSON.stringify(expanded));
  };

  const collapseRatingInfo = () => {
    const destinationsWithExpandedRatingInfo = [...expanded];
    destinationsWithExpandedRatingInfo.splice(id, 1);
    setExpanded(destinationsWithExpandedRatingInfo);
    setViewRatingDescriptions(false);
    sessionStorage.setItem("ratingInfoExpanded", JSON.stringify(expanded));
  };

  return (
    <>
      <div className="RatingInfoButtonContainer">
        {!viewRatingDescriptions ? (
          <button className="RatingInfoButton" onClick={expandRatingInfo}>
            View Rating Descriptions
          </button>
        ) : (
          <button className="RatingInfoButton" onClick={collapseRatingInfo}>
            Close Rating Descriptions
          </button>
        )}
      </div>
      <div id="RatingInfo">
        {viewRatingDescriptions ? (
          <>
            <div className="RatingFlagsContainer">
              <img
                className="RatingCriteriaFlag"
                src={RatingFlag}
                alt="GGOG Rating Flag Icon"
              />
              <img
                className="RatingCriteriaFlag"
                src={RatingFlag}
                alt="GGOG Rating Flag Icon"
              />
              <img
                className="RatingCriteriaFlag"
                src={RatingFlag}
                alt="GGOG Rating Flag Icon"
              />
              <img
                className="RatingCriteriaFlag"
                src={RatingFlag}
                alt="GGOG Rating Flag Icon"
              />
            </div>
            <div className="MainText">
              <p>
                An absolute delight, not necessarily a championship test but
                great holiday spirit and local flavor, wonderful scenery, a
                perfect place and setting for a relaxed round of golf.
              </p>
            </div>
            <div className="RatingFlagsContainer">
              <img
                className="RatingCriteriaFlag"
                src={RatingFlag}
                alt="GGOG Rating Flag Icon"
              />
              <img
                className="RatingCriteriaFlag"
                src={RatingFlag}
                alt="GGOG Rating Flag Icon"
              />
              <img
                className="RatingCriteriaFlag"
                src={RatingFlag}
                alt="GGOG Rating Flag Icon"
              />
              <img
                className="RatingCriteriaFlag"
                src={RatingFlag}
                alt="GGOG Rating Flag Icon"
              />
              <img
                className="RatingCriteriaFlag"
                src={RatingFlag}
                alt="GGOG Rating Flag Icon"
              />
            </div>
            <p className="MainText">
              Championship courses that would be among the highest rated in almost any
              individual locale, and other GGOG{" "}
              <img
                className="RatingCriteriaFlag"
                src={RatingFlag}
                alt="GGOG Rating Flag Icon"
                style={{
                  height: "12px",
                  width: "12px",
                  marginBottom: "3px",
                  marginLeft: "-2px",
                  marginRight: "-4px",
                }}
              />{" "}
              ™&nbsp;favorites that are full of character. A great experience.
            </p>
            <div className="RatingFlagsContainer">
              <img
                className="RatingCriteriaFlag"
                src={RatingFlag}
                alt="GGOG Rating Flag Icon"
              />
              <img
                className="RatingCriteriaFlag"
                src={RatingFlag}
                alt="GGOG Rating Flag Icon"
              />
              <img
                className="RatingCriteriaFlag"
                src={RatingFlag}
                alt="GGOG Rating Flag Icon"
              />
              <img
                className="RatingCriteriaFlag"
                src={RatingFlag}
                alt="GGOG Rating Flag Icon"
              />
              <img
                className="RatingCriteriaFlag"
                src={RatingFlag}
                alt="GGOG Rating Flag Icon"
              />
              <img
                className="RatingCriteriaFlag"
                src={RatingFlag}
                alt="GGOG Rating Flag Icon"
              />
            </div>
            <p className="MainText">
              A true GGOG{" "}
              <img
                className="RatingCriteriaFlag"
                src={RatingFlag}
                alt="GGOG Rating Flag Icon"
                style={{
                  height: "12px",
                  width: "12px",
                  marginBottom: "3px",
                  marginLeft: "-2px",
                  marginRight: "-4px",
                }}
              />{" "}
              ™&nbsp;“trophy course”, in terms of atmosphere, challenge,
              history, one-of-a-kind setting, or all of the above! Absolutely
              unforgettable, a highlight of any trip.
            </p>
            <div className="RatingFlagsContainer">
              <img
                className="RatingCriteriaFlag"
                src={RatingFlag}
                alt="GGOG Rating Flag Icon"
              />
              <img
                className="RatingCriteriaFlag"
                src={RatingFlag}
                alt="GGOG Rating Flag Icon"
              />
              <img
                className="RatingCriteriaFlag"
                src={RatingFlag}
                alt="GGOG Rating Flag Icon"
              />
              <img
                className="RatingCriteriaFlag"
                src={RatingFlag}
                alt="GGOG Rating Flag Icon"
              />
              <img
                className="RatingCriteriaFlag"
                src={RatingFlag}
                alt="GGOG Rating Flag Icon"
              />
              <img
                className="RatingCriteriaFlag"
                src={RatingFlag}
                alt="GGOG Rating Flag Icon"
              />
              <img
                className="RatingCriteriaFlag"
                src={RatingFlag}
                alt="GGOG Rating Flag Icon"
              />
            </div>
            <p className="MainText">
              Best of the best, utterly spectacular in every way.
              <img
                style={{
                  height: "28px",
                  marginLeft: "2px",
                  filter: "drop-shadow(0px 0px 3px white)",
                }}
                src={BucketEmoji}
                alt="Bucket List Course"
              />{" "}
              indicates one of the handful of most elite courses in all the world, many
              offering a once-in-a-lifetime type experience.
            </p>
          </>
        ) : null}
      </div>
    </>
  );
};

export default RatingInfo;
