import React from "react";
import "./CardButton.css";

const CardButton = ({buttonText}) => {
  return (
  <div className="CardButtonContainer">
  <button className="CardButton">
    {buttonText}
  </button>
  </div>
  );
};

export default CardButton;
