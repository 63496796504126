import React from "react";
import { Link } from "react-router-dom";
import FlagIcon from "../../Assets/FlagIcon.png";
import "./Footer.css";
import FlagIconFooter from "../../Assets/FlagIconFooter.png";
import FooterLogo from "./../../Assets/FlynnWorldLLCLogo.png";
import LegalRow from "./LegalRow/LegalRow";
import NavigationBottom from "./NavigationBottom/NavigationBottom";

const Footer = () => {
  return (
    <div id="Footer">
      <Link to="/">
      <img id="FooterIcon" src={FlagIconFooter} alt="GGOG Flag Logo" title="the GGOG flag is a trademark of ggog.com" />
      </Link>
      <NavigationBottom/>
      <div className="FooterText">
        ©2023 Great Game of Golf™{" "}
        <span>
          <img id="FooterFlag" src={FlagIcon} alt="GGOG Flag Icon" />
        </span>{" "}
        &nbsp;A &nbsp;
        <img id="FooterLogo" src={FooterLogo} alt="FlynnWorld LLC" />
        &nbsp;&nbsp;Company. &nbsp; All Rights Reserved.  &nbsp;  greatgameofgolf@gmail.com 
      </div>
      <LegalRow/>
    </div>
  );
};

export default Footer;
