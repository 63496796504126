import React from "react";
import { useLoaderData } from "react-router-dom";
import SecondaryButton from "../../../../Components/Buttons/SecondaryButton/SecondaryButton";
import BackToTopButton from "../../../../Components/Buttons/ScrollButtons/BackToTopButton/BackToTopButton";
import BackButton from "../../../../Components/Buttons/BackButton/BackButton";
import RestaurantOrPubCard from "../../../../Components/Cards/RestaurantOrPubCard/RestaurantOrPubCard";
import SubscribeForm from "../../../../Components/SubscribeForm/SubscribeForm";
import FlagIcon from "../../../../Assets/FlagIconLarger.png";

export const RestaurantsAndPubsLoader = async ({ params }) => {
  try {
    const [restaurantsAndPubs, region] = await Promise.all([
      fetch(`/api/restaurantsandpubs/?region=${params.id}`).then((res) =>
        res.json()
      ),
      fetch(`/api/regions/${params.id}`).then((res) => res.json()),
    ]);
    return {
      restaurantsAndPubs,
      region,
    };
  } catch (error) {
    console.log("Fetch error:", error);
  }
};
const RestaurantsAndPubs = () => {
  const { restaurantsAndPubs, region } = useLoaderData();

  return (
    <>
      <div>
      <div className="NavigationBufferZone"></div>
        <div className="PageHeadline">
          {region.regionName} Restaurants and Pubs
        </div>
        <div>
          <SecondaryButton text="Request Information" link="/starter" />
        </div>
          <BackButton
            text={`${region.regionName}`}
            link={`/destinations/${region.destinationName}/${region.regionName}/${region._id}`}
          />
        {restaurantsAndPubs.length ? (
          <>
            <p className="MainText" style={{ textAlign: "center", marginTop: "20px" }}>
              <img
                src={FlagIcon}
                alt="GGOG Flag Icon"
                id="RatingFlag"
                style={{
                  height: "25px",
                  width: "25px",
                  marginLeft: "2px",
                  marginRight: "2px",
                  marginBottom: "5px",
                }}
              />
              after cuisine/drink icons indicates location at/adjacent to course
            </p>
            <div className="row CardContainer">
              {restaurantsAndPubs
                // .sort((a, b) => b.rating - a.rating)
                .map((restaurantOrPub) => (
                  <div
                    className="col-xs-1 sm-2 md-3 lg-4"
                    key={restaurantOrPub._id}
                  >
                    <RestaurantOrPubCard
                      specialty={restaurantOrPub.specialty}
                      britishAleServed={restaurantOrPub.britishAleServed}
                      guinness={restaurantOrPub.guinness}
                      whiteWine={restaurantOrPub.whiteWine}
                      seafood={restaurantOrPub.seafood}
                      establishmentName={restaurantOrPub.establishmentName}
                      thumbnail={restaurantOrPub.thumbnail}
                      destinationName={region.destinationName}
                      regionName={region.regionName}
                      name={restaurantOrPub.name}
                      atCourse={restaurantOrPub.atCourse}
                      id={restaurantOrPub._id}
                      buttonText={`Enter ${restaurantOrPub.shortName}`}
                    />
                  </div>
                ))}
            </div>
          </>
        ) : null}
        {restaurantsAndPubs.length > 4 ? <BackToTopButton /> : null}
        <SubscribeForm />
      </div>
    </>
  );
};

export default RestaurantsAndPubs;
