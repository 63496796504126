import React from "react";
import { useLoaderData } from "react-router-dom";
import MainImageButton from "../../../../Components/Buttons/MainImageButton/MainImageButton";
import SecondaryButton from "../../../../Components/Buttons/SecondaryButton/SecondaryButton";
import IntroText from "../../../../Components/TextComponents/IntroText/IntroText";
import BackToTopButton from "../../../../Components/Buttons/ScrollButtons/BackToTopButton/BackToTopButton";
import BackButton from "../../../../Components/Buttons/BackButton/BackButton";
import SubscribeForm from "../../../../Components/SubscribeForm/SubscribeForm";
import ComingSoon from "../../FeaturePageAssets/ComingSoon.webp";
import ComingSoonMobile from "../../FeaturePageAssets/ComingSoonMobile.webp";
import "../Suppliers.css";

export const ThingToDoLoader = async ({ params }) => {
  try {
    const res = await fetch(`/api/thingstodo/${params.id}`);
    return res.json();
  } catch (error) {
    console.log("Fetch error:", error);
  }
};

const ThingToDo = () => {
  const thingToDo = useLoaderData();

  return (
    <>
      <div>
        <div className="MainImageContainer">
          {thingToDo.mainImage ? (
            <img
              className="MainImage"
              src={thingToDo.mainImage}
              alt={`${thingToDo.thingToDoName} ${thingToDo.city} ${thingToDo.destinationName}`}
            />
          ) : (
            <img
              className="MainImage"
              src={ComingSoon}
              alt={`${thingToDo.thingToDoName} ${thingToDo.city} ${thingToDo.destinationName}`}
            />
          )}
          <div className="MainImageText">{thingToDo.thingToDoName}</div>
          <div className="MainImageButtonContainer">
            <MainImageButton text="Plan Your Visit" link="/starter" />
          </div>
        </div>
        <div className="MainImageContainerMobile">
          {thingToDo.mainImageMobile ? (
            <img
              className="MainImage"
              src={thingToDo.mainImageMobile}
              alt={`${thingToDo.thingToDoName} ${thingToDo.city} ${thingToDo.destinationName}`}
            />
          ) : (
            <img
              className="MainImage"
              src={ComingSoonMobile}
              alt={`${thingToDo.thingToDoName} ${thingToDo.city} ${thingToDo.destinationName}`}
            />
          )}
          <div className="MainImageText">{thingToDo.thingToDoName}</div>
          <div className="MainImageButtonContainer">
            <MainImageButton text="Plan Your Visit" link="/starter" />
          </div>
        </div>
        <div>
          <div className="PageHeadline" id="SupplierName">
            {thingToDo.thingToDoName}
          </div>
          {thingToDo.city &&
          thingToDo.stateEquivalent &&
          thingToDo.stateEquivalent !== thingToDo.destinationName ? (
            <div className="PageSubHeadline" id="SupplierLocation">
              {thingToDo.city}, {thingToDo.stateEquivalent},{" "}
              {thingToDo.destinationName}
            </div>
          ) : (
            <div className="PageSubHeadline" id="SupplierLocation">
              {thingToDo.city}, {thingToDo.stateEquivalent}
            </div>
          )}
          <div>
            {thingToDo.introduction ? (
              <IntroText text={thingToDo.introduction} id={thingToDo._id} />
            ) : null}
          </div>
        </div>
        <div>
          <SecondaryButton text="Request Information" link="/starter" />
        </div>
        <div className="SuppliersBackButtonsContainer">
          <div>
            {thingToDo.region ? (
              <BackButton
                text="Things to Do"
                link={`/thingstodo/${thingToDo.destinationName}/${thingToDo.region.regionName}/${thingToDo.region._id}`}
              />
            ) : (
              <BackButton
                text={thingToDo.destinationName}
                link={`/destinations/${thingToDo.destinationName}/${thingToDo.destination}`}
              />
            )}
          </div>
          <div>
            {thingToDo.region ? (
              <BackButton
                text={`${thingToDo.region.regionName}`}
                link={`/destinations/${thingToDo.region.destinationName}/${thingToDo.region.regionName}/${thingToDo.region._id}`}
              />
            ) : null}
          </div>
        </div>
      </div>
      <BackToTopButton />
      <SubscribeForm />
    </>
  );
};

export default ThingToDo;
