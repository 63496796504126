import React from "react";
import { useLoaderData } from "react-router-dom";
import MainImageButton from "../../../../Components/Buttons/MainImageButton/MainImageButton";
import SecondaryButton from "../../../../Components/Buttons/SecondaryButton/SecondaryButton";
import IntroText from "../../../../Components/TextComponents/IntroText/IntroText";
import BackToTopButton from "../../../../Components/Buttons/ScrollButtons/BackToTopButton/BackToTopButton";
import BackButton from "../../../../Components/Buttons/BackButton/BackButton";
import SubscribeForm from "../../../../Components/SubscribeForm/SubscribeForm";
import ComingSoon from "../../FeaturePageAssets/ComingSoon.webp";
import ComingSoonMobile from "../../FeaturePageAssets/ComingSoonMobile.webp";
import FlagsContainer from "../../../../Components/RatingContainers/FlagsContainer/FlagsContainer";
import MemberLogo from "../../../../Assets/GGOGMemberLogo.png";
// import Carousel from "../../../../Components/Carousel/Carousel";
import SwiperSlider from "../../../../Components/SwiperSlider/SwiperSlider";
import "../Suppliers.css";

export const CourseLoader = async ({ params }) => {
  try {
    const res = await fetch(`/api/courses/${params.id}`);
    return res.json();
  } catch (error) {
    console.log("Fetch error:", error);
  }
};

const Course = () => {
  const course = useLoaderData();
  //using original slider
  // let courseImages = course.courseImages.map((image) => ({
  // below is an example of adding Cloudinary autosizing params to end of url
  // original: `${image}/600w/400`,
  //   original: image,
  //   originalAlt: course.courseName,
  //   thumbnail: image,
  //   thumbnailAlt: `${course.shortName} thumbnail`
  // }));
  // console.log(courseImages);

  let courseImages = course.courseImages.map((image) => ({
    // below is an example of adding Cloudinary autosizing params to end of url
    // original: `${image}/600w/400`,
    imageUrl: image.imageUrl,
    imageAlt: course.courseName,
    _id: image._id,
    thumbnail: image.thumbnail,
    thumbnailAlt: `${course.shortName} thumbnail`,
  }));

  return (
    <>
      <div>
        <div className="MainImageContainer">
          {course.mainImage ? (
            <img
              className="MainImage"
              src={course?.mainImage}
              alt={course.courseName}
            />
          ) : (
            <img
              className="MainImage"
              src={ComingSoon}
              alt={course.courseName}
            />
          )}
          <div className="MainImageText">{course.courseName}</div>
          {course.member ? (
            <div className="MemberLogoMainImageContainer">
              <img
                className="MemberLogoMainImage"
                src={MemberLogo}
                alt="GGOG Member Logo"
                title={`${course.shortName} is a GGOG™ Partner`}
              ></img>
            </div>
          ) : null}
          {course.member && course.courseName.length <= 35 ? (
            <div className="SupplierLogoContainer">
              <img
                className="SupplierLogo"
                src={course.logo}
                alt={`${course.courseName} logo`}
              />
            </div>
          ) : null}
          {course.member && course.courseName.length > 35 ? (
            <div className="SupplierLogoContainerLongName">
              <img
                className="SupplierLogo"
                src={course.logo}
                alt={`${course.courseName} logo`}
              />
            </div>
          ) : null}
          <div className="MainImageButtonContainer">
            <MainImageButton text="Plan Your Visit" link="/starter" />
          </div>
        </div>
        <div className="MainImageContainerMobile">
          {course.mainImageMobile ? (
            <img
              className="MainImage"
              src={course?.mainImageMobile}
              alt={course.courseName}
            />
          ) : (
            <img
              className="MainImage"
              src={ComingSoonMobile}
              alt={course.courseName}
            />
          )}
          <div className="MainImageText">{course.courseName}</div>
          <div className="MainImageButtonContainer">
            <MainImageButton text="Plan Your Visit" link="/starter" />
          </div>
        </div>
        <div>
          <div className="PageHeadline" id="SupplierName">
            {course.courseName}
          </div>
          <FlagsContainer rating={course.rating} bucketExperience={course.bucketExperience}/>
          {course.city &&
          course.stateEquivalent &&
          course.stateEquivalent !== course.destinationName ? (
            <div className="PageSubHeadline" id="SupplierLocation">
              {course.city}, {course.stateEquivalent}, {course.destinationName}
            </div>
          ) : (
            <div className="PageSubHeadline" id="SupplierLocation">
              {course.city}, {course.stateEquivalent}
            </div>
          )}
          {course.member ? (
            <div className="MemberLogoContainer">
              <img
                className="MemberLogo"
                src={MemberLogo}
                alt="GGOG Member Logo"
                title={`${course.shortName} is a GGOG™ Partner`}
              ></img>
            </div>
          ) : null}
          <div>
            {course.introduction ? (
              <IntroText text={course.introduction} id={course._id} />
            ) : null}
          </div>
        </div>
        <div>
          <SecondaryButton text="Request Information" link="/starter" />
        </div>
        <div className="SuppliersBackButtonsContainer">
          <div>
            {course.region ? (
              <BackButton
                text={`${course.region.regionName}`}
                link={`/destinations/${course.region.destinationName}/${course.region.regionName}/${course.region._id}`}
              />
            ) : null}
          </div>
          <div>
            {course.destinationName && course.destination ? (
              <BackButton
                text={`${course.destinationName}`}
                link={`/destinations/${course.destinationName}/${course.destination}`}
              />
            ) : null}
          </div>
        </div>
        {course.member ? (
          <div>
            {course.courseImages.length ? (
              <>
                <div className="PageSubHeadline">
                  {course.courseName} Photos
                </div>
                {course.logo ? (
                  <div className="SupplierLogoContainer">
                    <img
                      className="SupplierLogo"
                      src={course.logo}
                      alt={`${course.courseName} logo`}
                    />
                  </div>
                ) : null}
                {/* <Carousel images={courseImages} /> */}
                <SwiperSlider images={courseImages} />
              </>
            ) : null}
          </div>
        ) : null}
      </div>
      <BackToTopButton />
      <SubscribeForm />
    </>
  );
};

export default Course;
