import React, { useState, useEffect, createRef } from "react";
import Alert from "../Alert/Alert";
import SecondaryButton from "../Buttons/SecondaryButton/SecondaryButton";
import "./SubscribeForm.css";
import API from "../../utils/API";

const SubscribeForm = () => {
  const [subscribeForm, setSubscribeForm] = useState({
    firstName: "",
    lastName: "",
    emailAddress: "",
  });

  const [showSubmitted, setShowSubmitted] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [contactSuccess, setContactSuccess] = useState(true);

  const recaptchaRef = createRef();

  const SITE_KEY = process.env.REACT_APP_reCAPTCHA_SITE_KEY;

  useEffect(() => {
    const loadScriptByURL = (id, url, callback) => {
      const isScriptExist = document.getElementById(id);

      if (!isScriptExist) {
        var script = document.createElement("script");
        script.type = "text/javascript";
        script.src = url;
        script.id = id;
        script.onload = function () {
          if (callback) callback();
        };
        document.body.appendChild(script);
      }

      if (isScriptExist && callback) callback();
    };

    // load the script by passing the URL
    loadScriptByURL(
      "recaptcha-key",
      `https://www.google.com/recaptcha/api.js?render=${SITE_KEY}`,
      function () {
        console.log("Script loaded!");
      }
    );
    return;
  }, [SITE_KEY]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setSubscribeForm({ ...subscribeForm, [name]: value });
  };

  const emailAddress = subscribeForm.emailAddress;

  const validateEmail = (emailAddress) => {
    return /\S+@\S+\.\S+/.test(emailAddress);
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    if (
      !subscribeForm.firstName ||
      !subscribeForm.lastName ||
      !subscribeForm.emailAddress ||
      !validateEmail(emailAddress)
    ) {
      setShowAlert(true);
      setContactSuccess(false);
      const timer = setTimeout(() => {
        setShowAlert(false);
        setContactSuccess(true);
      }, 3000);
      return () => clearTimeout(timer);
    } else {
      API.createSubscribe(subscribeForm)
        .then(() => {
          console.log(subscribeForm);
        })
        .catch((err) => {
          throw err;
        });
      const token = await window.grecaptcha.execute(SITE_KEY, {
        action: "submit",
      });
      let form = {
        ...subscribeForm,
        "g-recaptcha-response": token,
      };
      console.log(form);

      setSubscribeForm({
        firstName: "",
        lastName: "",
        emailAddress: "",
      });
      setShowSubmitted(true);
      const timer = setTimeout(() => {
        setShowAlert(false);
        setShowSubmitted(false);
      }, 3000);
      return () => clearTimeout(timer);
    }
  };

  return (
    <div id="SubscribeForm">
      <div id="SubscribeHeadline">
        Join the GGOG<sup id="SubscribeTMSign">™</sup>
        <span id="SubscribeHeadlineFlag"></span> Gallery<sup id="SubscribeTMSign">™</sup>!  Subscribe to our Newsletter!
      </div>
      {showSubmitted ? (
        contactSuccess ? (
          <Alert
            message="You have subscribed successfully, thank you!"
            type="success"
          />
        ) : (
          <Alert
            message="There was an issue submitting your message, please email us 
              instead at greatgameofgolf@gmail.com"
            type="danger"
          />
        )
      ) : null}
      {showAlert && !subscribeForm.firstName ? (
        <Alert message="Please enter your first name" type="danger" />
      ) : null}
      {showAlert && !subscribeForm.lastName ? (
        <Alert message="Please enter your last name" type="danger" />
      ) : null}
      {showAlert && !subscribeForm.emailAddress ? (
        <Alert message="Please enter your email address" type="danger" />
      ) : null}
      {showAlert &&
      subscribeForm.emailAddress &&
      !validateEmail(emailAddress) ? (
        <Alert message="Please check your email address format" type="danger" />
      ) : null}
      <form className="contact-form">
        <div className="row" id="SubscribeFields">
          <div className="col-md-4 form-group form-group--firstName">
            <label
              className="SubscribeFieldTitle"
              htmlFor="subscribe-firstName"
            >
              First Name <span className="required">*</span>
            </label>
            <input
              onChange={handleInputChange}
              className="form-control"
              type="text"
              name="firstName"
              value={subscribeForm.firstName}
              id="contact-firstName"
              required
            />
          </div>
          <div className="col-md-4 form-group form-group--lastName">
            <label className="SubscribeFieldTitle" htmlFor="subscribe-lastName">
              Last Name <span className="required">*</span>
            </label>
            <input
              onChange={handleInputChange}
              className="form-control"
              type="text"
              name="lastName"
              value={subscribeForm.lastName}
              id="contact-lastName"
              required
            />
          </div>
          <div className="col-md-4 form-group form-group--email">
            <label className="SubscribeFieldTitle" htmlFor="subscribe-email">
              Email Address <span className="required">*</span>
            </label>
            <input
              onChange={handleInputChange}
              className="form-control"
              type="email"
              name="emailAddress"
              value={subscribeForm.emailAddress}
              id="contact-email"
              required
            />
          </div>
        </div>
        <SecondaryButton
          className="form-control"
          onClick={handleFormSubmit}
          type="submit"
          text="Subscribe Now!"
        />
        <div className="col-12">
          <label htmlFor="starter-recaptcha" style={{ marginTop: "0.5rem" }}>
            <small>
              This site is protected by reCAPTCHA and the Google{" "}
              <a
                href="https://policies.google.com/privacy"
                target="_blank"
                rel="noopener noreferrer"
              >
                Privacy&nbsp;Policy
              </a>{" "}
              and{" "}
              <a
                href="https://policies.google.com/terms"
                target="_blank"
                rel="noopener noreferrer"
              >
                Terms&nbsp;of&nbsp;Service
              </a>{" "}
              apply.
            </small>
          </label>
        </div>
        <div
          className="g-recaptcha"
          data-sitekey={SITE_KEY}
          data-size="invisible"
          ref={recaptchaRef}
        />
      </form>
    </div>
  );
};

export default SubscribeForm;
