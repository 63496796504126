import React from "react";
import RatingStar from "../../../Assets/RatingStar.png";
import FlagIcon from "../../../Assets/FlagIconLarger.png";
import "../RatingContainers.css";

const StarsContainer = ({ rating, nearCourse }) => {
  const ratingRounded = Math.round(rating);
  const starsArray = new Array(ratingRounded).fill(
    <img src="" alt="star icon" />
  );
  return (
    <div className="RatingContainer">
      <div
        className="row"
        title={`Accommodation star rating = ${ratingRounded}`}
      >
        {starsArray.map((star, index) => (
          <div className="col-xs-1" key={index}>
            <img
              className="RatingIcon"
              id="RatingStar"
              src={RatingStar}
              alt="flag rating icon"
            />
          </div>
        ))}
        {nearCourse ? (
          <div className="col-xs-1">
            <img
              className="RatingIcon"
              src={FlagIcon}
              alt="flag rating icon"
              title="Accommodation is located at or adjacent to the course"
            />
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default StarsContainer;
