import React from "react";
import { useLoaderData, Link } from "react-router-dom";
import MainImageButton from "../../../../Components/Buttons/MainImageButton/MainImageButton";
import SecondaryButton from "../../../../Components/Buttons/SecondaryButton/SecondaryButton";
import IntroText from "../../../../Components/TextComponents/IntroText/IntroText";
import BackToTopButton from "../../../../Components/Buttons/ScrollButtons/BackToTopButton/BackToTopButton";
import BackButton from "../../../../Components/Buttons/BackButton/BackButton";
import SubscribeForm from "../../../../Components/SubscribeForm/SubscribeForm";
import ComingSoon from "../../FeaturePageAssets/ComingSoon.webp";
import ComingSoonMobile from "../../FeaturePageAssets/ComingSoonMobile.webp";
import StarsContainer from "../../../../Components/RatingContainers/StarsContainer/StarsContainer";
import MemberLogo from "../../../../Assets/GGOGMemberLogo.png";
import SwiperSlider from "../../../../Components/SwiperSlider/SwiperSlider";
import "../Suppliers.css";
import "./Accommodation.css";

export const AccommodationLoader = async ({ params }) => {
  try {
    const res = await fetch(`/api/accommodations/${params.id}`);
    return res.json();
  } catch (error) {
    console.log("Fetch error:", error);
  }
};

const Accommodation = () => {
  const accommodation = useLoaderData();

  let accommodationImages = accommodation.accommodationImages.map((image) => ({
    imageUrl: image.imageUrl,
    imageAlt: accommodation.accommodationName,
    _id: image._id,
    thumbnail: image.thumbnail,
    thumbnailAlt: `${accommodation.shortName} thumbnail`,
  }));

  return (
    <>
      <div>
        <div className="MainImageContainer">
          {accommodation.mainImage ? (
            <img
              className="MainImage"
              src={accommodation.mainImage}
              alt={`${accommodation.accommodationName} ${accommodation.city} ${accommodation.destinationName}`}
            />
          ) : (
            <img
              className="MainImage"
              src={ComingSoon}
              alt={`${accommodation.accommodationName} ${accommodation.city} ${accommodation.destinationName}`}
            />
          )}
          <div className="MainImageText">{accommodation.accommodationName}</div>
          {accommodation.member ? (
            <div className="MemberLogoMainImageContainer">
              <img
                className="MemberLogoMainImage"
                src={MemberLogo}
                alt="GGOG Member Logo"
                title={`${accommodation.shortName} is a GGOG™ Partner`}
              ></img>
            </div>
          ) : null}
          <div className="MainImageButtonContainer">
            <MainImageButton text="Plan Your Visit" link="/starter" />
          </div>
        </div>
        <div className="MainImageContainerMobile">
          {accommodation.mainImageMobile ? (
            <img
              className="MainImage"
              src={accommodation.mainImageMobile}
              alt={`${accommodation.accommodationName} ${accommodation.city} ${accommodation.destinationName}`}
            />
          ) : (
            <img
              className="MainImage"
              src={ComingSoonMobile}
              alt={`${accommodation.accommodationName} ${accommodation.city} ${accommodation.destinationName}`}
            />
          )}
          <div className="MainImageText">{accommodation.accommodationName}</div>
          <div className="MainImageButtonContainer">
            <MainImageButton text="Plan Your Visit" link="/starter" />
          </div>
        </div>
        <div>
          <div className="PageHeadline" id="SupplierName">
            {accommodation.accommodationName}
          </div>
          <StarsContainer
            rating={accommodation.rating}
            nearCourse={accommodation.nearCourse}
          />
          {accommodation.city &&
          accommodation.stateEquivalent &&
          accommodation.stateEquivalent !== accommodation.destinationName ? (
            <div className="PageSubHeadline" id="SupplierLocation">
              {accommodation.city}, {accommodation.stateEquivalent},{" "}
              {accommodation.destinationName}
            </div>
          ) : (
            <div className="PageSubHeadline" id="SupplierLocation">
              {accommodation.city}, {accommodation.stateEquivalent}
            </div>
          )}
          {accommodation.member ? (
            <div className="MemberLogoContainer">
              <img
                className="MemberLogo"
                src={MemberLogo}
                alt="GGOG Member Logo"
                title={`${accommodation.shortName} is a GGOG™ Partner`}
              ></img>
            </div>
          ) : null}
          <div>
            {accommodation.introduction ? (
              <IntroText
                text={accommodation.introduction}
                id={accommodation._id}
              />
            ) : null}
          </div>
        </div>
        <div>
          <SecondaryButton text="Request Information" link="/starter" />
        </div>
        <div className="SuppliersBackButtonsContainer">
          <div>
            {accommodation.region ? (
              <BackButton
                text="Places to Stay"
                link={`/placestostay/${accommodation.destinationName}/${accommodation.region.regionName}/${accommodation.region._id}`}
              />
            ) : (
              <BackButton text="All Destinations" link="/destinations" />
            )}
          </div>
          <div>
            {accommodation.region ? (
              <BackButton
                text={accommodation.region.regionName}
                link={`/destinations/${accommodation.region.destinationName}/${accommodation.region.regionName}/${accommodation.region._id}`}
              />
            ) : (
              <BackButton text="All Destinations" link="/destinations" />
            )}
          </div>
          <div>
            {accommodation.destinationName &&
            accommodation.destination &&
            accommodation.member ? (
              <BackButton
                text={accommodation.destinationName}
                link={`/destinations/${accommodation.destinationName}/${accommodation.destination}`}
              />
            ) : null}
          </div>
        </div>
        {accommodation.member ? (
          <div>
            {accommodation.accommodationImages.length ? (
              <>
                <div className="PageSubHeadline">
                  {accommodation.accommodationName} Photos
                </div>
                {accommodation.logo ? (
                  <div className="SupplierLogoContainer">
                    <img
                      className="SupplierLogo"
                      src={accommodation.logo}
                      alt={`${accommodation.accommodationName} logo`}
                    />
                  </div>
                ) : null}
                {/* <Carousel images={courseImages} /> */}
                <SwiperSlider images={accommodationImages} />
              </>
            ) : null}
            {accommodation.nearbyCourses.length ? (
              <>
                <div className="DriveTimeChartHeadline">
                  {accommodation.shortName} Drive Time Chart
                </div>
                <div className="DriveTimeChartContainer">
                  <div className="DriveTimeChart">
                    <table>
                      <tbody>
                        <tr>
                          <th>Course</th>
                          <th>Distance</th>
                          <th>Approx. Drive Time</th>
                        </tr>
                        {accommodation.nearbyCourses.map((course) => (
                          <tr key={course._id}>
                            <td key={`${course._id} name`}>
                              <div>
                                <Link
                                  className="DriveTimeChartSupplierName"
                                  to={`/courses/${accommodation.destinationName}/${course.course.courseName}/${course.course._id}`}
                                >
                                  {course.course.shortName}
                                </Link>
                              </div>
                            </td>
                            <td key={`${course._id} distance`}>
                              {course.distance}
                            </td>
                            <td key={`${course._id} driveTime`}>
                              {course.driveTime}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </>
            ) : null}
          </div>
        ) : null}
      </div>
      <BackToTopButton />
      <SubscribeForm />
    </>
  );
};

export default Accommodation;
