import React from "react";
import "../ScrollButtons.css";

const ScrollToIDButton = ({text, idToScrollTo, category}) => {
  const ScrollUp = () => {
    document.getElementById(`${idToScrollTo}`).scrollIntoView({ behavior: "smooth" });
  };

  return (
    <div className="ScrollButtonContainer">
      <button className="ScrollButton">
        <p
          className="ScrollButtonText"
          style={{ textAlign: "center" }}
          onClick={() => {
            ScrollUp();
          }}
        >
         {text} {category}
        </p>
      </button>
    </div>
  );
};

export default ScrollToIDButton;