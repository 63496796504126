import React from "react";
import { Link } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavigationLogo from "./NavigationLogo.png";
import "./Navigation.css";

const Navigation = () => {
  return (
    <Navbar id="Navbar" expand="lg">
      <Container>
        <Link to="/">
          <img id="NavbarLogo" src={NavigationLogo} alt="GGOG Great Game of Golf Logo" />
        </Link>
        <Navbar.Toggle id="NavbarToggler" />
        <Navbar.Collapse id="NavbarCollapse">
          <Nav id="Nav" className="ml-auto">
            <Link className="NavLink" id="DestinationsLink" to="/destinations">
              Destinations
            </Link>
            <Link className="NavLink" to="/about">
              About GGOG™
            </Link>
            <Link className="NavLink" to="/starter">
              Visit The Starter™
            </Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default Navigation;
