import React from "react";
import { Link } from "react-router-dom";
import "./SecondaryButton.css";

const SecondaryButton = ({ text, link, onClick }) => {
  return (
    <div className="ButtonContainer">
      <Link id="SecondaryButtonLinkText" to={link}>
        <button onClick={onClick} id="SecondaryButton">{text}</button>
      </Link>
    </div>
  );
};

export default SecondaryButton;
