import { useEffect, useRef } from "react";
import "./SwiperSlider.css";

export default function SwiperSlider({ images }) {
  const swiperRef = useRef(null);
  const swiperThumbsRef = useRef(null);

  useEffect(() => {
    // Object with parameters
    const params = {
      slidesPerView: 1,
      lazy: true,
      zoom: true,
      effect: "fade",
      fadeEffect: {
        crossFade: true,
      },
      navigation: true,
      pagination: {
        type: "fraction",
      },
      thumbs: true,
      loop: true,
      injectStyles: [
        `
          .swiper-button-next,
          .swiper-button-prev {
            color: white;
          }
          .swiper-button-next:active,
          .swiper-button-prev:active {
            color: black;
          }
          .swiper-pagination-fraction{
            color: white;
            text-shadow: 1px 1px 1px black;
            font-style: bold;
            font-size: 18px;
            cursor: default;
          }
          :host {
            --swiper-navigation-size: 44px;
        }
        @media only screen and (max-width: 1000px) {
            :host {
                --swiper-navigation-size: 40px;
            }
            .swiper-pagination-fraction{
                font-size: 16px;
              }
          }
          .swiper-button-next {
            right: var(--swiper-navigation-sides-offset,8px);
          }
          .swiper-button-prev {
            left: var(--swiper-navigation-sides-offset,8px);
          }
          @media only screen and (max-width: 600px) {
            :host {
                --swiper-navigation-size: 36px;
            }
            .swiper-pagination-fraction{
                font-size: 15px;
              }
              .swiper-button-next {
                right: var(--swiper-navigation-sides-offset,6px);
              }
              .swiper-button-prev {
                left: var(--swiper-navigation-sides-offset,6px);
              }
          }
        @media only screen and (max-width: 450px) {
            :host {
                --swiper-navigation-size: 32px;
            }
            .swiper-pagination-fraction{
                font-size: 14px;
              }
              .swiper-button-next {
                right: var(--swiper-navigation-sides-offset,4px);
              }
              .swiper-button-prev {
                left: var(--swiper-navigation-sides-offset,4px);
              }
          }
      `,
      ],
    };

    // Assign it to swiper element
    Object.assign(swiperRef.current, params);

    // initialize swiper
    swiperRef.current.initialize();

    const thumbsParams = {
      slidesPerView: 4,
      breakpoints: {
        450: {
          slidesPerView: 6,
        },
      },
      loop: true,
      watchSlidesProgress: true,
    };
    // Assign thumbs to swiper element
    Object.assign(swiperThumbsRef.current, thumbsParams);

    // initialize thumbs swiper
    swiperThumbsRef.current.initialize();
  }, []);

  return (
    <>
      <div className="ZoomText">Double-click or tap to zoom</div>
      <swiper-container init="false" ref={swiperRef} thumbs-swiper=".my-thumbs">
        {images.map((image) => (
          //need to make sure there are no duplicate urls in the array otherwise there will be a duplicate key
          <swiper-slide key={image._id}>
            {/* <div className="img-protect"> */}
            <div className="swiper-zoom-container">
              <img
                src={image.imageUrl}
                alt={image.imageAlt}
                class="swiper-lazy"
              />
            </div>
            {/* </div> */}
          </swiper-slide>
        ))}
      </swiper-container>
      <swiper-container init="false" ref={swiperThumbsRef} class="my-thumbs">
        {images.map((image) => (
          //need to make sure there are no duplicate urls in the array otherwise there will be a duplicate key
          <swiper-slide key={image._id} class="swiper-slide-auto">
            <div className="img-protect">
            <img src={image.thumbnail} alt={image.thumbnailAlt}/>
            </div>
          </swiper-slide>
        ))}
      </swiper-container>
    </>
  );
}
