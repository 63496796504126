import React from "react";
import "./DestinationCard.css";
import CardButton from "../../Buttons/CardButton/CardButton";
import { Link } from "react-router-dom";
import Icon from "../../../Assets/GGOGIconTM.png";
import "../Cards.css";

const DestinationCard = ({ destinationName, thumbnail, buttonText, id }) => {
  return (
    <Link to={`/destinations/${destinationName}/${id}`}>
      <div className="TravelCard">
        <div className="GGOGIconContainer">
          <img className="GGOGIcon" src={Icon} alt="GGOG Icon"></img>
        </div>
        <div className="CardContentContainer">
          <img
            className="TravelCardThumbnailImage"
            src={thumbnail}
            alt={`${destinationName} golf trips`}
          />
          <div className="CardName" id="DestinationName">
            {destinationName}
          </div>
          <CardButton buttonText={buttonText} />
        </div>
      </div>
    </Link>
  );
};

export default DestinationCard;
