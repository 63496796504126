import React from "react";
import "../ScrollButtons.css";

const BackToTop = () => {
  const ScrollUp = () => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };

  return (
    <div className="ScrollButtonContainer">
      <button className="ScrollButton">
        <p
          className="ScrollButtonText"
          style={{ textAlign: "center" }}
          onClick={() => {
            ScrollUp();
          }}
        >
          Back to Top
        </p>
      </button>
    </div>
  );
};

export default BackToTop;
