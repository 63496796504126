import React from "react";
import ReactDOM from "react-dom/client";
import {
  createBrowserRouter,
  RouterProvider,
  ScrollRestoration,
  Outlet,
} from "react-router-dom";
import About from "./Pages/About/About";
import Course, { CourseLoader } from "./Pages/FeaturePages/Suppliers/Course/Course";
import Accommodation, { AccommodationLoader } from "./Pages/FeaturePages/Suppliers/Accommodation/Accommodation";
import DestinationsAll, {
  DestinationsLoader,
} from "./Pages/DestinationsAll/DestinationsAll";
import DestinationSingle, {
  DestinationSingleLoader,
} from "./Pages/FeaturePages/DestinationSingle/DestinationSingle";
import Region, { RegionLoader } from "./Pages/FeaturePages/Region/Region";
import PlacesToStay, {PlacesToStayLoader} from "./Pages/FeaturePages/CategoryPages/PlacesToStay/PlacesToStay";
import RestaurantsAndPubs, {RestaurantsAndPubsLoader} from "./Pages/FeaturePages/CategoryPages/RestaurantsAndPubs/RestaurantsAndPubs";
import RestaurantOrPub, {RestaurantOrPubLoader} from "./Pages/FeaturePages/Suppliers/RestaurantOrPub/RestaurantOrPub";
import ThingsToDo, {ThingsToDoLoader} from "./Pages/FeaturePages/CategoryPages/ThingsToDo/ThingsToDo";
import ThingToDo, {ThingToDoLoader} from "./Pages/FeaturePages/Suppliers/ThingToDo/ThingToDo";
import PrivacyPolicy from "./Pages/Legal/PrivacyPolicy";
import TermsofUse from "./Pages/Legal/TermsofUse";
import Footer from "./Components/Footer/Footer";
import Home, { HomeLoader } from "./Pages/Home/Home";
import HomeThankYou from "./Pages/Home/HomeThankYou";
import Navigation from "./Components/Navigation/Navigation";
import Starter from "./Pages/Starter/Starter";
import ErrorBoundary from "./Pages/ErrorBoundary/ErrorBoundary";

import "./App.css";

const Layout = () => (
  <>
    <Navigation />
    <Outlet />
    <ScrollRestoration
      getKey={(location, matches) => {
        return location.pathname;
      }}
    />
    <Footer />
  </>
);

const router = createBrowserRouter([
  {
    element: <Layout />,
    children: [
      {
        path: "/",
        element: <Home />,
        loader: HomeLoader,
      },
      {
        path: "/thankyou",
        element: <HomeThankYou />,
        loader: HomeLoader,
      },
      {
        path: "/destinations",
        element: <DestinationsAll />,
        loader: DestinationsLoader,
      },
      {
        path: "/destinations/:destinationName/:id",
        element: <DestinationSingle />,
        loader: DestinationSingleLoader,
      },
      {
        path: "/destinations/:destinationName/:regionName/:id",
        element: <Region />,
        loader: RegionLoader,
      },
      {
        path: "/placestostay/:destinationName/:regionName/:id",
        element: <PlacesToStay/>,
        loader: PlacesToStayLoader,
      },
      {
        path: "/restaurantsandpubs/:destinationName/:regionName/:id",
        element: <RestaurantsAndPubs/>,
        loader: RestaurantsAndPubsLoader,
      },
      {
        path: "/thingstodo/:destinationName/:regionName/:id",
        element: <ThingsToDo/>,
        loader: ThingsToDoLoader,
      },
      {
        path: "/restaurantsandpubs/:destinationName/:regionName/:establishmentName/:id",
        element: <RestaurantOrPub/>,
        loader: RestaurantOrPubLoader,
      },
      {
        path: "/thingstodo/:destinationName/:regionName/:thingToDoName/:id",
        element: <ThingToDo/>,
        loader: ThingToDoLoader,
      },
      {
        path: "/placestostay/:destinationName/:regionName/:accommodationName/:id",
        element: <Accommodation />,
        loader: AccommodationLoader,
      },
      {
        path: "/courses/:destinationName/:courseName/:id",
        element: <Course />,
        loader: CourseLoader,
      },
      {
        path: "/about",
        element: <About />,
      },
      {
        path: "/starter",
        element: <Starter />,
      },
      {
        path: "/privacypolicy",
        element: <PrivacyPolicy />,
      },
      {
        path: "/termsofuse",
        element: <TermsofUse />,
      },
      {
        path: "*",
        element: <Home />,
        loader: HomeLoader,
      },
    ],
    errorElement: <ErrorBoundary />,
  },
]);

ReactDOM.createRoot(document.getElementById("root")).render(
  <>
    <RouterProvider router={router} />
  </>
);
