import React from "react";
import "./NavigationBottom.css";
import { Link } from "react-router-dom";

const NavigationBottom = () => {
  return (
    <>
      <div id="NavigationBottom">
        <div className="row" id="NavigationBottomLinksContainer">
          <div className="col-lg-3">
            <Link className="NavigationBottomLinks" to="/">
              Home
            </Link>
          </div>
          <div className="col-lg-3">
            <Link className="NavigationBottomLinks" to="/about">
              About
            </Link>
          </div>
          <div className="col-lg-3">
            <Link className="NavigationBottomLinks" to="/starter">
              Contact
            </Link>
          </div>
          <div className="col-lg-3">
            <Link className="NavigationBottomLinks" to="/destinations">
              Destinations
            </Link>
          </div>
          </div>
      </div>
    </>
  );
};

export default NavigationBottom;
