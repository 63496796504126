import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import "./Legal.css";

const PrivacyPolicy = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const ScrollUp = (event) => {
    window.scrollTo(0, 0);
  };

  return (
    <div>
      <div className="Legal">
      <div className="NavigationBufferZone"></div>
      <br/>
        <div className="LegalHeadline">
          Privacy Policy
          <br />
          FOR
          <br />
          Great Game of Golf™ (www.ggog.com)
        </div>
        <br />
        <br />
        <div className="LegalText">
          <div>
            <span className="LastModified">Last Modified: July 5, 2023</span>
            <br />
            <br />
            <span className="LegalSectionHeadline">Introduction</span>
            <br />
            Great Game of Golf™ (GGOG™), a FlynnWorld LLC Company ("Company",
            "we", or "us") respects your privacy and is committed to protecting
            it through our compliance with this policy.
            <br />
            <br />
            This policy describes the types of information we may collect from
            you or that you may provide when you visit the website ggog.com (our
            "Website") and our practices for collecting, using, maintaining,
            protecting, and disclosing that information.
            <br />
            <br />
            This policy applies to information we collect:
            <ul>
              <li>On this Website.</li>
              <li>
                In email, text, and other electronic messages between you and
                this Website.
              </li>
              <li>
                When you interact with our advertising and applications on
                third-party websites and services, if those applications or
                advertising include links to this policy.
              </li>
              <li>
                When you post any information to this Website (if applicable).
              </li>
            </ul>
            It does not apply to information collected by:
            <ul>
              <li>
                Us offline or through any other means, including on any other
                website operated by Company or any third party (including our
                affiliates and subsidiaries); or
              </li>
              <li>
                Any third party (including our affiliates and subsidiaries),
                including through any application or content (including
                advertising) that may link to or be accessible from or through
                the Website.
              </li>
            </ul>
            Please read this policy carefully to understand our policies and
            practices regarding your information and how we will treat it. If
            you do not agree with our policies and practices, your choice is not
            to use our Website. By accessing or using this Website, you agree to
            this privacy policy. This policy may change from time to time
            (see&nbsp;
            <a className="LegalLink" href="#ChangesToOurPrivacyPolicy">
              Changes to Our Privacy Policy.)
            </a>
            &nbsp; Your continued use of this Website after we make changes is
            deemed to be acceptance of those changes, so please check the policy
            periodically for updates.
            <br />
            <br />
            <span className="LegalSectionHeadline">
              Children Under the Age of 16
            </span>
            <br />
            Our Website is not intended for children under 16 years of age. No
            one under age 16 may provide any [personal] information to or on the
            Website. We do not knowingly collect personal information from
            children under 16. If you are under 16, do not use or provide any
            information on this Website or through any of its features, register
            on the Website, use any of the interactive or public comment
            features of this Website, or provide any information about yourself
            to us, including your name, address, telephone number, email
            address, or any screen name or user name you may use. If we learn we
            have collected or received personal information from a child under
            16 without verification of parental consent, we will delete that
            information. If you believe we might have any information from or
            about a child under 16, please contact us via{" "}
            <Link className="LegalLink" to="/starter">
              our Starter page
            </Link>
            , found at https://www.ggog.com/starter, or email us at
            greatgameofgolf@gmail.com.
            {/* <br />
            <br />
            California residents under 16 years of age may have additional
            rights regarding the collection and sale of their personal
            information. Please see{" "}
            <a href="https://oag.ca.gov/privacy/privacy-laws">
              Your State Privacy Rights
            </a>{" "}
            for more information. */}
            <br />
            <br />
            <span className="LegalSectionHeadline">
              Information We Collect About You and How We Collect It
            </span>
            <br />
            We collect several types of information from and about users of our
            Website, including information: but subject to earlier removal or
            termination as provided for in the{" "}
            <Link className="LegalLink" to="/termsofuse">
              Terms of Use,{" "}
            </Link>{" "}
            found at https://www.ggog.com/termsofuse.
            <ul>
              <li>
                By which you may be personally identified, such as name, postal
                address, email address, telephone number, by which you may be
                contacted online or offline ("personal information");
              </li>
              <li>
                That is about you but individually does not identify you, such
                as your state of residence, visits to the Website; and/or
              </li>
              <li>
                About your internet connection, the equipment you use to access
                our Website, and usage details.
              </li>
            </ul>
            We collect this information:
            <ul>
              <li>Directly from you when you provide it to us.</li>
              <li>
                Automatically as you navigate through the site. Information
                collected automatically may include usage details, IP addresses,
                and information collected through cookies, web beacons, and
                other tracking technologies.
              </li>
              <li>From third parties, for example, our business partners.</li>
            </ul>
            <span className="InItalics">Information You Provide to Us</span>
            <br />
            The information we collect on or through our Website may include:
            <ul>
              <li>
                Information that you provide by filling in forms on our Website.
                This includes information provided at the time of registering to
                use our Website, subscribing to our service, posting material
                (if applicable), requesting information about a possible
                vacation, or requesting further services. We may also ask you
                for information when you enter a contest or promotion sponsored
                by us, and when you report a problem with our Website.
              </li>
              <li>
                Records and copies of your correspondence (including email
                addresses), if you contact us.
              </li>
              <li>
                Your responses to surveys that we might ask you to complete for
                research purposes.
              </li>
              <li>
                Details of transactions you carry out through our Website and of
                the fulfillment of your orders. You may be required to provide
                financial information before placing an order through our
                Website.
              </li>
              <li>Your search queries on the Website.</li>
              <li>Any other types of information you provide.</li>
            </ul>
            You also may provide information to be published or displayed
            (hereinafter, "posted") on public areas of the Website, or
            transmitted to other users of the Website or third parties
            (collectively, "User Contributions"). Your User Contributions are
            posted on and transmitted to others at your own risk. Although we
            limit access to certain pages, please be aware that no security
            measures are perfect or impenetrable. Additionally, we cannot
            control the actions of other users of the Website with whom you may
            choose to share your User Contributions. Therefore, we cannot and do
            not guarantee that your User Contributions will not be viewed by
            unauthorized persons.
            <br />
            <br />
            <span className="InItalics">
              Information We Collect Through Automatic Data Collection
              Technologies{" "}
            </span>
            <br />
            As you navigate through and interact with our Website, we may use
            automatic data collection technologies to collect certain
            information about your equipment, browsing actions, and patterns,
            including:
            <div>
              <ul>
                <li>
                  Details of your visits to our Website, including traffic data,
                  location data, logs, and other communication data and the
                  resources that you access and use on the Website.
                </li>
                <li>
                  Information about your computer and internet connection,
                  including your IP address, operating system, and browser type.
                </li>
              </ul>
            </div>
            We also may use these technologies to collect information about your
            online activities over time and across third-party websites or other
            online services (behavioral tracking).
            {/* Click here [URL LINK TO DO
            NOT TRACK DISCLOSURES] for information on how you can opt out of
            behavioral tracking on this website and how we respond to web
            browser signals and other mechanisms that enable consumers to
            exercise choice about behavioral tracking. */}
            <br />
            <br />
            The information we collect automatically may include personal
            information, or we may maintain it or associate it with personal
            information we collect in other ways or receive from third parties.
            It helps us to improve our Website and to deliver a better and more
            personalized service, including by enabling us to:
            <ul>
              <li>Estimate our audience size and usage patterns.</li>
              <li>
                Store information about your preferences, allowing us to
                customize our Website according to your individual interests.
              </li>
              <li>Speed up your searches.</li>
              <li>Recognize you when you return to our Website.</li>
            </ul>
            The technologies we use for this automatic data collection may
            include:
            <ul>
              <li>
                <span className="LegalSectionHeadline">
                  Cookies (or browser cookies).
                </span>{" "}
                A cookie is a small file placed on the hard drive of your
                computer. You may refuse to accept browser cookies by activating
                the appropriate setting on your browser. However, if you select
                this setting you may be unable to access certain parts of our
                Website. Unless you have adjusted your browser setting so that
                it will refuse cookies, our system will issue cookies when you
                direct your browser to our Website.{" "}
              </li>
              <li>
                <span className="LegalSectionHeadline">Web Beacons.</span>Pages
                of our Website and our emails may contain small electronic files
                known as web beacons (also referred to as clear gifs, pixel
                tags, and single-pixel gifs) that permit the Company, for
                example, to count users who have visited those pages or opened
                an email and for other related website statistics (for example,
                recording the popularity of certain website content and
                verifying system and server integrity).{" "}
              </li>
              <li>
                <span className="LegalSectionHeadline">Flash Cookies.</span>
                Certain features of our Website may use local stored objects (or
                Flash cookies) to collect and store information about your
                preferences and navigation to, from, and on our Website. Flash
                cookies are not managed by the same browser settings as are used
                for browser cookies. For information about managing your privacy
                and security settings for Flash cookies, see{" "}
                <a
                  className="LegalLink"
                  href="#ChoicesAboutHowWeUseandDiscloseYourInformation"
                >
                  Choices About How We Use and Disclose Your Information.
                </a>
              </li>
            </ul>
            <span className="LegalSectionHeadline">
              Third-Party Use of Cookies and Other Tracking Technologies
            </span>
            <br />
            Some content or applications, including advertisements, on the
            Website are served by third-parties, including advertisers, ad
            networks and servers, content providers, and application providers.
            These third parties may use cookies alone or in conjunction with web
            beacons or other tracking technologies to collect information about
            you when you use our website. The information they collect may be
            associated with your personal information or they may collect
            information, including personal information, about your online
            activities over time and across different websites and other online
            services. They may use this information to provide you with
            interest-based (behavioral) advertising or other targeted content.
            <br />
            <br />
            We do not control these third parties' tracking technologies or how
            they may be used. If you have any questions about an advertisement
            or other targeted content, you should contact the responsible
            provider directly. For information about how you can opt out of
            receiving targeted advertising from many providers, see{" "}
            <a
              className="LegalLink"
              href="#ChoicesAboutHowWeUseandDiscloseYourInformation"
            >
              Choices About How We Use and Disclose Your Information.
            </a>
            <br />
            <br />
            <span className="LegalSectionHeadline">
              How We Use Your Information
            </span>
            <br />
            We use information that we collect about you or that you provide to
            us, including any personal information:
            <ul>
              <li>To present our Website and its contents to you.</li>
              <li>
                To provide you with information, products, or services that you
                request from us.
              </li>
              <li>To fulfill any other purpose for which you provide it.</li>
              <li>
                To provide you with notices about your account/subscription,
                including expiration and renewal notices.
              </li>
              <li>
                To carry out our obligations and enforce our rights arising from
                any contracts entered into between you and us, including for
                billing and collection.
              </li>
              <li>
                To notify you about changes to our Website or any products or
                services we offer or provide though it.
              </li>
              <li>
                To allow you to participate in interactive features on our
                Website.
              </li>
              <li>
                In any other way we may describe when you provide the
                information.
              </li>
              <li>For any other purpose with your consent.</li>
            </ul>
            We may also use your information to contact you about our own and
            third-parties' goods and services that may be of interest to you. If
            you do not want us to use your information in this way, you can
            opt-out by checking/not checking (as applicable) the relevant box
            located on the form on which we collect your data (the registration
            form). You can also always opt-out by clicking the unsubscribe link
            on a marketing or promotional email, or by contacting us via{" "}
            <Link className="LegalLink" to="/starter">
              our Starter page
            </Link>
            , found at https://www.ggog.com/starter, or by emailing us at
            greatgameofgolf@gmail.com. &nbsp;For more information, see&nbsp;
            <a
              className="LegalLink"
              href="#ChoicesAboutHowWeUseandDiscloseYourInformation"
            >
              Choices About How We Use and Disclose Your Information.
            </a>
            <br />
            <br />
            We may use the information we have collected from you to enable us
            to display advertisements to our advertisers' target audiences. Even
            though we do not disclose your personal information for these
            purposes without your consent, if you click on or otherwise interact
            with an advertisement, the advertiser may assume that you meet its
            target criteria.
            <br />
            <br />
            <span className="LegalSectionHeadline">
              Disclosure of Your Information
            </span>
            <br />
            We may disclose aggregated information about our users, and
            information that does not identify any individual, without
            restriction.
            <br />
            <br />
            We may disclose personal information that we collect or you provide
            as described in this privacy policy:
            <ul>
              <li>To our subsidiaries and affiliates.</li>
              <li>
                To contractors, service providers, and other third parties we
                use to support our business and who are bound by contractual
                obligations to keep personal information confidential and use it
                only for the purposes for which we disclose it to them.
              </li>
              <li>
                To a buyer or other successor in the event of a merger,
                divestiture, restructuring, reorganization, dissolution, or
                other sale or transfer of some or all of the Company's assets,
                whether as a going concern or as part of bankruptcy,
                liquidation, or similar proceeding, in which personal
                information held by the Company about our Website users is among
                the assets transferred.
              </li>
              <li>
                To third parties to market their products or services to you if
                you have not opted out of these disclosures. We contractually
                require these third parties to keep personal information
                confidential and use it only for the purposes for which we
                disclose it to them. For more information, see{" "}
                <a
                  className="LegalLink"
                  href="#ChoicesAboutHowWeUseandDiscloseYourInformation"
                >
                  Choices About How We Use and Disclose Your Information.
                </a>
              </li>
              <li>
                To fulfill the purpose for which you provide it. For example, if
                you give us an email address to use the "email a friend" feature
                of our Website, we will transmit the contents of that email and
                your email address to the recipients.
              </li>
              <li>
                For any other purpose disclosed by us when you provide the
                information.
              </li>
              <li>With your consent.</li>
            </ul>
            We may also disclose your personal information:
            <ul>
              <li>
                To comply with any court order, law, or legal process, including
                to respond to any government or regulatory request.
              </li>{" "}
              <li>
                To enforce or apply our{" "}
                <Link className="LegalLink" to="/termsofuse">
                  Terms of Use,
                </Link>
                &nbsp;found at https://www.ggog.com/termsofuse, or terms of sale
                and other agreements, including for billing and collection
                purposes.
              </li>
              <li>
                If we believe disclosure is necessary or appropriate to protect
                the rights, property, or safety of the Company, our customers,
                or others. This includes exchanging information with other
                companies and organizations for the purposes of fraud protection
                and credit risk reduction.
              </li>
            </ul>
            <span
              id="ChoicesAboutHowWeUseandDiscloseYourInformation"
              className="LegalSectionHeadline"
            >
              Choices About How We Use and Disclose Your Information
            </span>
            <br />
            We strive to provide you with choices regarding the personal
            information you provide to us. We have created mechanisms to provide
            you with the following control over your information:
            <ul>
              <li>
                <span className="LegalSectionHeadline">
                  Tracking Technologies and Advertising.
                </span>{" "}
                You can set your browser to refuse all or some browser cookies,
                or to alert you when cookies are being sent. If you disable or
                refuse cookies, please note that some parts of this site may
                then be inaccessible or not function properly.
              </li>
              <li>
                {" "}
                <span className="LegalSectionHeadline">
                  Disclosure of Your Information for Third-Party Advertising.
                </span>{" "}
                If you do not want us to share your personal information with
                unaffiliated or non-agent third parties for promotional
                purposes, you can opt-out checking/not checking (as applicable)
                the relevant box located on the form on which we collect your
                data (the registration form). You can also always opt-out by
                clicking the unsubscribe link on a marketing or promotional
                email, or by contacting us via{" "}
                <Link className="LegalLink" to="/starter">
                  our Starter page
                </Link>
                , found at https://www.ggog.com/starter, or via email at
                greatgameofgolf@gmail.com.
              </li>
              <li>
                {" "}
                <span className="LegalSectionHeadline">
                  Promotional Offers from the Company.
                </span>{" "}
                {/* If you do not wish to have your email address/contact
                information used by the Company to promote our own or third
                parties' products or services, you can opt-out by checking the
                relevant box located on the form on which we collect your data
                (the registration form]) or at any other time by logging into
                the Website and adjusting your user preferences in your account
                profile by checking or unchecking the relevant boxes. If we have
                sent you a promotional email, you may send us a return email
                asking to be omitted from future email distributions. This opt
                out does not apply to information provided to the Company as a
                result of a product purchase, warranty registration, product
                service experience or other transactions. */}
                If you do not wish to have your email address/contact
                information used by the Company to promote our own or third
                parties' products or services, you can opt-out by clicking the
                unsubscribe link on any promotional email that we send you. Or,
                by contacting us via{" "}
                <Link className="LegalLink" to="/starter">
                  our Starter page
                </Link>
                , found at https://www.ggog.com/starter, or via email at
                greatgameofgolf@gmail.com. If we have sent you a promotional
                email, you may send us a return email asking to be omitted from
                future email distributions. This opt out does not apply to
                information provided to the Company as a result of a product
                purchase, warranty registration, product service experience or
                other transactions.
              </li>
              <li>
                {" "}
                <span className="LegalSectionHeadline">
                  Targeted Advertising
                </span>{" "}
                If you do not want us to use information that we collect or that
                you provide to us to deliver advertisements according to our
                advertisers' target-audience preferences, you can opt-out by
                contacting us via{" "}
                <Link className="LegalLink" to="/starter">
                  our Starter page
                </Link>
                , found at https://www.ggog.com/starter, or by emailing us at
                greatgameofgolf@gmail.com. We do not control third parties'
                collection or use of your information to serve interest-based
                advertising. However these third parties may provide you with
                ways to choose not to have your information collected or used in
                this way. You can opt out of receiving targeted ads from members
                of the Network Advertising Initiative ("NAI") on the NAI's
                website.
              </li>
            </ul>
            {/* <br />
            <br />
            Residents of certain states, such as California, Nevada, Colorado,
            Connecticut, Virginia, and Utah may have additional personal
            information rights and choices. Please see Your State Privacy Rights
            for more information. */}
            {/* <br /> */}
            {/* <br /> */}
            <span className="LegalSectionHeadline">
              Accessing and Correcting Your Information
            </span>
            <br />
            {/* You can review and change your personal information by logging into the Website and visiting your account profile page.  */}
            You may also contact us via{" "}
            <Link className="LegalLink" to="/starter">
              our Starter page
            </Link>
            , found at https://www.ggog.com/starter, or email us at
            greatgameofgolf@gmail.com, to request access to, correct or delete
            any personal information that you have provided to us. We cannot
            delete your personal information except by also deleting your user
            account. We may not accommodate a request to change information if
            we believe the change would violate any law or legal requirement or
            cause the information to be incorrect.
            <br />
            <br />
            If you delete or request to delete your User Contributions (as
            applicable) from the Website, copies of your User Contributions may
            remain viewable in cached and archived pages, or might have been
            copied or stored by other Website users. Proper access and use of
            information provided on the Website, including User Contributions,
            is governed by{" "}
            <Link className="LegalLink" to="/termsofuse">
              our Terms of Use,{" "}
            </Link>{" "}
            found at https://www.ggog.com/termsofuse.
            <br />
            <br />
            {/* <span className="LegalSectionHeadline">
              Your State Privacy Rights
            </span>
            <br />
            State consumer privacy laws may provide their residents with
            additional rights regarding our use of their personal information.
            To learn more about California residents' privacy rights, visit the
            CCPA and refer to the CPRA Privacy Notice for California Residents.
            California's "Shine the Light" law (Civil Code Section § 1798.83)
            permits users of our App that are California residents to request
            certain information regarding our disclosure of personal information
            to third parties for their direct marketing purposes. To make such a
            request, please contact us via{" "}
            <Link to="/Contact">our Contact page.</Link> */}
            {/* <br />
            <br />
            To learn more about consumer privacy rights in other states and how
            to exercise them, refer to their individual state privacy notices.
            <br />
            <br /> */}
            {/* OR 
            <br/>
            <br/>
            Colorado, Connecticut, Virginia, and Utah each provide their
            state residents with rights to: Confirm whether we process their
            personal information. Access and delete certain personal
            information. Data portability. Opt-out of personal data processing
            for targeted advertising and sales. Colorado, Connecticut, and
            Virginia also provide their state residents with rights to: Correct
            inaccuracies in their personal information, taking into account the
            information's nature processing purpose. Opt-out of profiling in
            furtherance of decisions that produce legal or similarly significant
            effects. To exercise any of these rights please [REQUEST SUBMISSION
            METHOD]. To appeal a decision regarding a consumer rights request
            [APPEAL PROCEDURE].] [Nevada provides its residents with a limited
            right to opt-out of certain personal information sales. Residents
            who wish to exercise this sale opt-out rights may submit a request
            to this designated address: [TOLL-FREE NUMBER/EMAIL/WEBSITE
            URL].[However, please know we do not currently sell data triggering
            that statute's opt-out requirements.]] */}
            <span className="LegalSectionHeadline">Data Security</span>
            <br />
            We have implemented measures designed to secure your personal
            information from accidental loss and from unauthorized access, use,
            alteration, and disclosure. All information you provide to us is
            stored on our secure servers behind firewalls. Any payment
            transactions will be completed by third-party processors using SSL
            technology.
            <br />
            <br />
            The safety and security of your information also depends on you.
            Where we have given you (or where you have chosen) a password for
            access to certain parts of our Website, you are responsible for
            keeping this password confidential. We ask you not to share your
            password with anyone. We urge you to be careful about giving out
            information in public areas of the Website like message boards. The
            information you share in public areas may be viewed by any user of
            the Website.
            <br />
            <br />
            Unfortunately, the transmission of information via the internet is
            not completely secure. Although we do our best to protect your
            personal information, we cannot guarantee the security of your
            personal information transmitted to our Website. Any transmission of
            personal information is at your own risk. We are not responsible for
            circumvention of any privacy settings or security measures contained
            on the Website.
            <br />
            <br />
            <span
              id="ChangesToOurPrivacyPolicy"
              className="LegalSectionHeadline"
            >
              Changes to Our Privacy Policy
            </span>
            <br />
            It is our policy to post any changes we make to our privacy policy
            on this page. If we make material changes to how we treat our users'
            personal information, we will notify you by email to the primary
            email address specified in your account or through a notice on the
            Website home page. The date the privacy policy was last revised is
            identified at the top of the page. You are responsible for ensuring
            we have an up-to-date active and deliverable email address for you,
            and for periodically visiting our Website and this privacy policy to
            check for any changes.
            <br />
            <br />
            <span className="LegalSectionHeadline">Contact Information</span>
            <br />
            To ask questions or comment about this privacy policy and our
            privacy practices, contact us via{" "}
            <Link className="LegalLink" to="/starter">
              our Starter page
            </Link>
            .
            {/* [ADDRESS/EMAIL ADDRESS] 
[or via our toll-free number: 
[TOLL-FREE NUMBER]]
[To register a complaint or concern, please [PRIVATE DISPUTE RESOLUTION INSTRUCTIONS].] */}
            <br />
            <br />
            <p
              className="LegalBackToTop"
              onClick={() => {
                ScrollUp();
              }}
            >
              Back to Top
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
