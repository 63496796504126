import React from "react";
import "./LegalRow.css";
import { Link } from "react-router-dom";

const LegalRow = () => {
  return (
    <>
      <div className="row" id="LegalRow">
      <div className="col-sm-6" id="TermsofUseLink">
            <Link className="LegalLinks" to="/termsofuse">
              Terms of Use
            </Link>
          </div>
          <div className="col-sm-6" id="PrivacyPolicyLink">
            <Link className="LegalLinks" to="/privacypolicy">
              Privacy Policy
            </Link>
          </div>
      </div>
      <br />
 
    </>
  );
};

export default LegalRow;