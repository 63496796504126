import React from "react";
import { useLoaderData } from "react-router-dom";
import Hero1800 from "../../Assets/GGOGHeroImage1800.webp";
import Hero1280 from "../../Assets/GGOGHeroImage1280.webp";
import Hero800 from "../../Assets/GGOGHeroImage800.webp";
import Logo from "../../Assets/Logo.png";
import DestinationCard from "../../Components/Cards/DestinationCard/DestinationCard";
import HomeText from "../../Components/TextComponents/HomeText/HomeText";
import SecondaryButton from "../../Components/Buttons/SecondaryButton/SecondaryButton";
import "./HomeThankYou.css";
import "./Home.css";
import SubscribeForm from "../../Components/SubscribeForm/SubscribeForm";

const HomeThankYou = () => {
  const destinations = useLoaderData();
  const featuredLocations = ["Scotland", "Ireland", "Northern Ireland", "England"];
  const featuredDestinations = destinations.filter((destination) =>
    featuredLocations.includes(destination.destinationName)
  );

  return (
    <>
      <div id="HomeThankYou">
        <div className="MainImageContainer" id="HeroImageContainer">
          <img
            id="HeroImage1800"
            src={Hero1800}
            alt="GGOG Great Game of Golf"
          />
          <img
            id="HeroImage1280"
            src={Hero1280}
            alt="GGOG Great Game of Golf"
          />
          <img
            id="HeroLogoThankYou"
            src={Logo}
            alt="GGOG Great Game of Golf Logo"
          />
          <div id="HeroTextThankYou" className="MainImageText">
            Thank You for Submitting!
          </div>
          <div id="ThankYouText2" className="MainImageText">
            We Will Respond Shortly!
          </div>
        </div>
        <div id="HeroImageContainerMobile">
          <img id="HeroImage800" src={Hero800} alt="GGOG Great Game of Golf" />
          <img id="HeroLogoThankYou" src={Logo} alt="GGOG Great Game of Golf Logo" />
          <div id="HeroTextThankYou" className="MainImageText">
            Thank You for Submitting!
          </div>
          <div id="ThankYouText2" className="MainImageText">
            We Will Respond Shortly!
          </div>
        </div>
        <div>
          <HomeText />
        </div>
        <div id="HomePageDestinationsHeadline" className="PageSubHeadline">
          Where do you want to{" "}
          <span>
            play?<sup className="PageSubHeadlineTMSign">™</sup>
          </span>
        </div>
        <div id="DestinationCardContainer" className="row">
          {featuredDestinations.map((destination) => (
            <div className="col-xs-1 sm-2 md-3 lg-4" key={destination._id}>
              <DestinationCard
                destinationName={destination.destinationName}
                thumbnail={destination.thumbnail}
                id={destination._id}
                buttonText={`Golf in ${destination.destinationName}`}
              />
            </div>
          ))}
        </div>
        <div className="ViewAllDestinationsButton">
          <SecondaryButton text="View All Destinations" link="/destinations" />
        </div>
      </div>
      <SubscribeForm />
    </>
  );
};

export default HomeThankYou;
