import React from "react";
import { useLoaderData } from "react-router-dom";
import MainImageButton from "../../../../Components/Buttons/MainImageButton/MainImageButton";
import SecondaryButton from "../../../../Components/Buttons/SecondaryButton/SecondaryButton";
import IntroText from "../../../../Components/TextComponents/IntroText/IntroText";
import BackToTopButton from "../../../../Components/Buttons/ScrollButtons/BackToTopButton/BackToTopButton";
import BackButton from "../../../../Components/Buttons/BackButton/BackButton";
import SubscribeForm from "../../../../Components/SubscribeForm/SubscribeForm";
import ComingSoon from "../../FeaturePageAssets/ComingSoon.webp";
import ComingSoonMobile from "../../FeaturePageAssets/ComingSoonMobile.webp";
import "../Suppliers.css";
import FlagIcon from "../../../../Assets/FlagIconLarger.png";
import PintofAle from "../../../../Assets/Emojis/BritishAleEmoji.png";
import PintofGuinness from "../../../../Assets/Emojis/GuinnessPintEmoji.png";
import SalmonEmoji from "../../../../Assets/Emojis/SalmonFiletEmoji.png";
import WhiteWineEmoji from "../../../../Assets/Emojis/WhiteWineEmoji.png";
import "./RestaurantOrPub.css";

export const RestaurantOrPubLoader = async ({ params }) => {
  try {
    const res = await fetch(`/api/restaurantsandpubs/${params.id}`);
    return res.json();
  } catch (error) {
    console.log("Fetch error:", error);
  }
};

const RestaurantOrPub = () => {
  const restaurantOrPub = useLoaderData();

  return (
    <>
      <div>
        <div className="MainImageContainer">
          {restaurantOrPub.mainImage ? (
            <img
              className="MainImage"
              src={restaurantOrPub.mainImage}
              alt={`${restaurantOrPub.establishmentName} ${restaurantOrPub.city} ${restaurantOrPub.destinationName}`}
            />
          ) : (
            <img
              className="MainImage"
              src={ComingSoon}
              alt={`${restaurantOrPub.establishmentName} ${restaurantOrPub.city} ${restaurantOrPub.destinationName}`}
            />
          )}
          <div className="MainImageText">
            {restaurantOrPub.establishmentName}
          </div>
          <div className="MainImageButtonContainer">
            <MainImageButton text="Plan Your Visit" link="/starter" />
          </div>
        </div>
        <div className="MainImageContainerMobile">
          {restaurantOrPub.mainImageMobile ? (
            <img
              className="MainImage"
              src={restaurantOrPub.mainImageMobile}
              alt={`${restaurantOrPub.establishmentName} ${restaurantOrPub.city} ${restaurantOrPub.destinationName}`}
            />
          ) : (
            <img
              className="MainImage"
              src={ComingSoonMobile}
              alt={`${restaurantOrPub.establishmentName} ${restaurantOrPub.city} ${restaurantOrPub.destinationName}`}
            />
          )}
          <div className="MainImageText">
            {restaurantOrPub.establishmentName}
          </div>
          <div className="MainImageButtonContainer">
            <MainImageButton text="Plan Your Visit" link="/starter" />
          </div>
        </div>
        <div>
          <div className="PageHeadline" id="SupplierName">
            {restaurantOrPub.establishmentName}
          </div>
          <div id="EmojiRow" title="Types of cuisine, and drink specialties">
            {restaurantOrPub.britishAleServed ? (
              <img
                className="RestaurantBritishAleEmoji"
                src={PintofAle}
                alt="Pint of Ale"
                title="Grab a pint of traditional Ale here"
              />
            ) : null}
              {restaurantOrPub.guinness ? (
              <img
                className="RestaurantBritishAleEmoji"
                src={PintofGuinness}
                alt="Pint of Guinness"
                title="Grab a pint of Guinness here"
              />
            ) : null}
            {restaurantOrPub.whiteWine ? (
              <img
                className="RestaurantBritishAleEmoji"
                src={WhiteWineEmoji}
                alt="Glass of white wine"
                title="Excellent white wine selection"
              />
            ) : null}
            {restaurantOrPub.specialty}
            {restaurantOrPub.seafood ? (
              <img
                className="RestaurantFoodSpecialEmoji"
                src={SalmonEmoji}
                alt="fresh Salmon filet"
                title="specializing in fresh local seafood"
              />
            ) : null}
            {restaurantOrPub.atCourse ? (
              <img
                id="RestaurantPubLocationFlag"
                className="RatingIcon"
                src={FlagIcon}
                alt="Establishment is located at or next to the course"
                title="Establishment is located at or next to the course"
              />
            ) : null}
          </div>
          {restaurantOrPub.city &&
          restaurantOrPub.stateEquivalent &&
          restaurantOrPub.stateEquivalent !==
            restaurantOrPub.destinationName ? (
            <div className="PageSubHeadline" id="SupplierLocation">
              {restaurantOrPub.city}, {restaurantOrPub.stateEquivalent},{" "}
              {restaurantOrPub.destinationName}
            </div>
          ) : (
            <div className="PageSubHeadline" id="SupplierLocation">
              {restaurantOrPub.city}, {restaurantOrPub.stateEquivalent}
            </div>
          )}
          <div>
            {restaurantOrPub.introduction ? (
              <IntroText
                text={restaurantOrPub.introduction}
                id={restaurantOrPub._id}
              />
            ) : null}
          </div>
        </div>
        <div>
          <SecondaryButton text="Request Information" link="/starter" />
        </div>
        <div className="SuppliersBackButtonsContainer">
          <div>
            {restaurantOrPub.region ? (
              <BackButton
                text="Restaurants and Pubs"
                link={`/restaurantsandpubs/${restaurantOrPub.destinationName}/${restaurantOrPub.region.regionName}/${restaurantOrPub.region._id}`}
              />
            ) : (
              <BackButton
                text={restaurantOrPub.destinationName}
                link={`/destinations/${restaurantOrPub.destinationName}/${restaurantOrPub.destination}`}
              />
            )}
          </div>
          <div>
            {restaurantOrPub.region ? (
              <BackButton
                text={`${restaurantOrPub.region.regionName}`}
                link={`/destinations/${restaurantOrPub.region.destinationName}/${restaurantOrPub.region.regionName}/${restaurantOrPub.region._id}`}
              />
            ) : null}
          </div>
        </div>
      </div>
      <BackToTopButton />
      <SubscribeForm />
    </>
  );
};

export default RestaurantOrPub;
