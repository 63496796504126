import React from "react";
import "../DestinationCard/DestinationCard.css";
import CardButton from "../../Buttons/CardButton/CardButton";
import { Link } from "react-router-dom";
import ComingSoonThumbnail from "../ComingSoonThumbnail.webp";
import Icon from "../../../Assets/GGOGIconTM.png";
import "./CategoryCard.css";
import "../Cards.css";

const CategoryCard = ({
  categoryURLText,
  categoryName,
  thumbnail,
  destinationName,
  regionName,
  buttonText,
  id,
}) => {
  return (
    <Link to={`/${categoryURLText}/${destinationName}/${regionName}/${id}`}>
      <div className="TravelCard">
        <div className="GGOGIconContainer">
          <img className="GGOGIcon" src={Icon} alt="GGOG Icon"></img>
        </div>
        <div className="CardContentContainer">
          {thumbnail ? (
            <img
              className="TravelCardThumbnailImage"
              src={thumbnail}
              alt={{ categoryName }}
            />
          ) : (
            <img
              className="TravelCardThumbnailImage"
              src={ComingSoonThumbnail}
              alt={{ categoryName }}
            />
          )}
          <div className="CardName" id="CategoryName">{categoryName}</div>
          <CardButton buttonText={buttonText} />
        </div>
      </div>
    </Link>
  );
};

export default CategoryCard;
