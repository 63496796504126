import React, { useEffect } from "react";
import Hero1800 from "../../Assets/GGOGHeroImage1800.webp";
import Hero1280 from "../../Assets/GGOGHeroImage1280.webp";
import Hero800 from "../../Assets/GGOGHeroImage800.webp";
import Logo from "../../Assets/Logo.png";
import Navigation from "../../Components/Navigation/Navigation";
import SecondaryButton from "../../Components/Buttons/SecondaryButton/SecondaryButton";
import "./ErrorBoundary.css";
import SubscribeForm from "../../Components/SubscribeForm/SubscribeForm";

const ErrorBoundary = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  
  return (
    <>
      <div id="ErrorBoundary">
        <Navigation />
        <div className="MainImageContainer" id="HeroImageContainer">
          <img
            id="HeroImage1800"
            src={Hero1800}
            alt="GGOG Great Game of Golf"
          />
          <img
            id="HeroImage1280"
            src={Hero1280}
            alt="GGOG Great Game of Golf"
          />
          <img
            id="HeroLogoThankYou"
            src={Logo}
            alt="GGOG Great Game of Golf Logo"
          />
          <div id="HeroTextThankYou" className="MainImageText">
            We're sorry, there has been an error.
          </div>
          <div id="ThankYouText2" className="MainImageText">
            Please contact us at greatgameofgolf@gmail.com
          </div>
        </div>
        <div id="HeroImageContainerMobile">
          <img id="HeroImage800" src={Hero800} alt="GGOG Great Game of Golf" />
          <img id="HeroLogo" src={Logo} alt="GGOG Great Game of Golf Logo" />
          <div id="HeroTextThankYou" className="MainImageText">
            We're sorry, there has been an error.
          </div>
          <div id="ThankYouText2" className="MainImageText">
            Please contact us at greatgameofgolf@gmail.com
          </div>
        </div>
        <div id="HomePageDestinationsHeadline" className="PageSubHeadline">
          Where do you want to{" "}
          <span>
            play?<sup className="PageSubHeadlineTMSign">™</sup>
          </span>
        </div>
        <div>
          <SecondaryButton text="View All Destinations" link="/destinations" />
        </div>
      </div>
      <SubscribeForm />
    </>
  );
};

export default ErrorBoundary;
