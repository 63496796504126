import React from "react";
import CardButton from "../../Buttons/CardButton/CardButton";
import { Link } from "react-router-dom";
import Icon from "../../../Assets/GGOGIconTM.png";
// import FlagIcon from "../../../Assets/FlagIconLarger.png";
import ComingSoonThumbnail from "../ComingSoonThumbnail.webp";
import "./ThingToDoCard.css";
import "../Cards.css";

const ThingToDoCard = ({
  thingToDoName,
  destinationName,
  regionName,
  thumbnail,
  buttonText,
  id,
}) => {
  return (
    <Link
      to={`/thingstodo/${destinationName}/${regionName}/${thingToDoName}/${id}`}
    >
      <div className="TravelCard">
        <div className="GGOGIconContainer">
          <img className="GGOGIcon" src={Icon} alt="GGOG Icon"></img>
        </div>
        <div className="CardContentContainer">
          {thumbnail ? (
            <img
              className="TravelCardThumbnailImage"
              src={thumbnail}
              alt={thingToDoName}
            />
          ) : (
            <img
              className="TravelCardThumbnailImage"
              src={ComingSoonThumbnail}
              alt={thingToDoName}
            />
          )}
          <div className="CardName" id="ThingToDoName">
            {thingToDoName}
          </div>
          <CardButton buttonText={buttonText} />
        </div>
      </div>
    </Link>
  );
};

export default ThingToDoCard;