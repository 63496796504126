import React from "react";
import CardButton from "../../Buttons/CardButton/CardButton";
import { Link } from "react-router-dom";
import Icon from "../../../Assets/GGOGIconTM.png";
import FlagIcon from "../../../Assets/FlagIconLarger.png";
import PintofAle from "../../../Assets/Emojis/BritishAleEmoji.png";
import PintofGuinness from "../../../Assets/Emojis/GuinnessPintEmoji.png";
import SalmonEmoji from "../../../Assets/Emojis/SalmonFiletEmoji.png";
import WhiteWineEmoji from "../../../Assets/Emojis/WhiteWineEmoji.png";
import ComingSoonThumbnail from "../ComingSoonThumbnail.webp";
import "./RestaurantOrPubCard.css";
import "../Cards.css";

const RestaurantOrPubCard = ({
  establishmentName,
  specialty,
  regionName,
  destinationName,
  thumbnail,
  buttonText,
  atCourse,
  britishAleServed,
  guinness,
  whiteWine,
  seafood,
  id,
}) => {
  return (
    <Link
      to={`/restaurantsandpubs/${destinationName}/${regionName}/${establishmentName}/${id}`}
    >
      <div className="TravelCard">
        <div className="GGOGIconContainer">
          <img className="GGOGIcon" src={Icon} alt="GGOG Icon"></img>
        </div>
        <div className="CardContentContainer">
          {thumbnail ? (
            <img
              className="TravelCardThumbnailImage"
              src={thumbnail}
              alt={establishmentName}
            />
          ) : (
            <img
              className="TravelCardThumbnailImage"
              src={ComingSoonThumbnail}
              alt={establishmentName}
            />
          )}
          <div className="CardName" id="EstablishmentName">
            {establishmentName}
            {specialty ? (
              <div
                id="EstablishmentSpecialty"
                title="Types of cuisine, and drink specialties"
              >
                {britishAleServed ? (
                  <img
                    className="RestaurantBritishAleEmoji"
                    id="RestaurantCardBritishAleEmoji"
                    src={PintofAle}
                    alt="Pint of Ale"
                    title="Grab a pint of traditional Ale here"
                  />
                ) : null}
                {guinness ? (
                  <img
                    className="RestaurantBritishAleEmoji"
                    id="RestaurantCardBritishAleEmoji"
                    src={PintofGuinness}
                    alt="Pint of Guinness"
                    title="Grab a pint of Guinness here"
                  />
                ) : null}
                {whiteWine ? (
                  <img
                    className="RestaurantBritishAleEmoji"
                    id="RestaurantCardBritishAleEmoji"
                    src={WhiteWineEmoji}
                    alt="Glass of white wine"
                    title="Excellent white wine selection"
                  />
                ) : null}
                {specialty}
                {seafood ? (
                  <img
                    className="RestaurantFoodSpecialEmoji"
                    src={SalmonEmoji}
                    alt="fresh Salmon filet"
                    title="specializing in fresh local seafood"
                  />
                ) : null}
                {atCourse ? (
                  <img
                    id="RestaurantPubCardLocationFlag"
                    className="RatingIcon"
                    src={FlagIcon}
                    alt="Establishment is located at or next to the course"
                    title="Establishment is located at or next to the course"
                  />
                ) : null}
              </div>
            ) : null}
          </div>
          <CardButton buttonText={buttonText} />
        </div>
      </div>
    </Link>
  );
};

export default RestaurantOrPubCard;
