import React, { useState, useEffect } from "react";
import "../TextComponents.css";
import { CSSTransition } from "react-transition-group";

const IntroText = ({ text, id }) => {
  const [visited, setVisited] = useState(() => {
    const destinationsVisited = sessionStorage.getItem("destinationsVisited");
    return JSON.parse(destinationsVisited) ?? [];
  });

  const [fullText, setFullText] = useState(() => {
    const destinationsVisited =
      sessionStorage.getItem("destinationsVisited") ?? [];
    return destinationsVisited.includes(id) ? true : false;
  });

  const shortText = text.substring(0, 500);

  const shortTextMobile = text.substring(0, 250);

  const trimmedShortText = shortText.substring(
    0,
    Math.min(shortText.length, shortText.lastIndexOf(" "))
  );

  const trimmedShortTextMobile = shortTextMobile.substring(
    0,
    Math.min(shortTextMobile.length, shortTextMobile.lastIndexOf(" "))
  );

  useEffect(() => {
    sessionStorage.setItem("destinationsVisited", JSON.stringify(visited));
  }, [visited]);

  const readMore = () => {
    setVisited([...visited, id]);
    setFullText(true);
    // sessionStorage.setItem("showAllDestinationText", JSON.stringify(fullText));
    sessionStorage.setItem("destinationsVisited", JSON.stringify(visited));
  };

  return (
    <>
      <div className="MainTextDesktop">
        <div className="MainText">
          {text.length <= 500 && <p>{text}</p>}
          {text.length > 500 && (
            <>
              <CSSTransition
                in={fullText}
                timeout={500}
                classNames="my-node"
                onEnter={() => setFullText(true)}
                onExited={() => setFullText(false)}
              >
                <p>
                  {fullText ? text : trimmedShortText}
                  <span>{fullText ? "" : "..."}</span>
                  {!fullText ? (
                    <button className="ToggleFullTextButton" onClick={readMore}>
                      {"Read More"}
                    </button>
                  ) : null}
                </p>
              </CSSTransition>
            </>
          )}
        </div>
      </div>
      <div className="MainTextMobile">
        <div className="MainText">
          {text.length <= 250 && <p>{text}</p>}
          {text.length > 250 && (
            <>
              <CSSTransition
                // nodeRef={nodeRef}
                in={fullText}
                timeout={500}
                classNames="my-node"
                onEnter={() => setFullText(true)}
                onExited={() => setFullText(false)}
              >
                <p>
                  {fullText ? text : trimmedShortTextMobile}
                  <span>{fullText ? "" : "..."}</span>
                  {!fullText ? (
                    <button className="ToggleFullTextButton" onClick={readMore}>
                      {"Read More"}
                    </button>
                  ) : null}
                </p>
              </CSSTransition>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default IntroText;
