import React, { useState, useEffect } from "react";
import { useLoaderData } from "react-router-dom";
import IntroText from "../../../Components/TextComponents/IntroText/IntroText";
import MainImageButton from "../../../Components/Buttons/MainImageButton/MainImageButton";
import SecondaryButton from "../../../Components/Buttons/SecondaryButton/SecondaryButton";
import BackToTopButton from "../../../Components/Buttons/ScrollButtons/BackToTopButton/BackToTopButton";
import ScrollToIDButton from "../../../Components/Buttons/ScrollButtons/ScrollToIDButton/ScrollToIDButton";
import BackButton from "../../../Components/Buttons/BackButton/BackButton";
import "./Region.css";
import CourseCard from "../../../Components/Cards/CourseCard/CourseCard";
import CategoryCard from "../../../Components/Cards/CategoryCard/CategoryCard";
import SubscribeForm from "../../../Components/SubscribeForm/SubscribeForm";
import RatingInfo from "../../../Components/RatingInfo/RatingInfo";

export const RegionLoader = async ({ params }) => {
  try {
    const [
      region,
      regionCourses,
      regionAccommodations,
      regionRestaurantsAndPubs,
      regionThingsToDo,
    ] = await Promise.all([
      fetch(`/api/regions/${params.id}`).then((res) => res.json()),
      fetch(`/api/courses?region=${params.id}`).then((res) => res.json()),
      fetch(`/api/accommodations?region=${params.id}`).then((res) =>
        res.json()
      ),
      fetch(`/api/restaurantsandpubs?region=${params.id}`).then((res) =>
        res.json()
      ),
      fetch(`/api/thingstodo?region=${params.id}`).then((res) => res.json()),
    ]);
    return {
      region,
      regionCourses,
      regionAccommodations,
      regionRestaurantsAndPubs,
      regionThingsToDo,
    };
  } catch (error) {
    console.log("Fetch error:", error);
  }
};

const Region = () => {
  const {
    region,
    regionCourses,
    regionAccommodations,
    regionRestaurantsAndPubs,
    regionThingsToDo,
  } = useLoaderData();

  const coursesByRating = [...regionCourses];

  const coursesSortedByRating = coursesByRating.sort((a, b) => {
    if (a.rating < b.rating) {
      return 1;
    } else if (a.rating > b.rating) {
      return -1;
    } else {
      if (a.shortName < b.shortName) {
        return -1;
      } else if (a.shortName > b.shortName) {
        return 1;
      } else {
        return 0;
      }
    }
  });

  const [sorted, setSorted] = useState(() => {
    const destinationsSorted = sessionStorage.getItem("destinationsSorted");
    return JSON.parse(destinationsSorted) ?? [];
  });

  const [ratingSort, setRatingSort] = useState(() => {
    const destinationsSorted =
      sessionStorage.getItem("destinationsSorted") ?? [];
    return destinationsSorted.includes(region._id) ? true : false;
  });

  useEffect(() => {
    sessionStorage.setItem("destinationsSorted", JSON.stringify(sorted));
  }, [sorted, ratingSort]);

  const sortCoursesAlphabetically = () => {
    const sortedAlphabetically = [...sorted];
    sortedAlphabetically.splice(region._id, 1);
    setSorted(sortedAlphabetically);
    setRatingSort(false);
    sessionStorage.setItem("destinationsSorted", JSON.stringify(sorted));
  };

  const sortCoursesByRating = () => {
    const sortedByRating = [...sorted];
    if (!sortedByRating.includes(region._id)) {
      sortedByRating.push(region._id);
    }
    setSorted(sortedByRating);
    setRatingSort(true);
    sessionStorage.setItem("destinationsSorted", JSON.stringify(sorted));
  };

  return (
    <>
      <div>
        <div className="MainImageContainer">
          <img
            className="MainImage"
            src={region?.mainImage}
            alt={`${region?.regionName} Golf Trips`}
          />
          <div className="MainImageText">{region?.regionName} Golf Trips</div>
          <div className="MainImageButtonContainer">
            <MainImageButton text="Start Planning Yours" link="/starter" />
          </div>
        </div>
        <div className="MainImageContainerMobile">
          <img
            className="MainImage"
            src={region?.mainImageMobile}
            alt={`${region?.regionName} Golf Trips`}
          />
          <div className="MainImageText">{region?.regionName} Golf Trips</div>
          <div className="MainImageButtonContainer">
            <MainImageButton text="Start Planning Yours" link="/starter" />
          </div>
        </div>
        <div>
          <div className="PageHeadline">
            {region?.regionName} Golf Vacations from GGOG
            <sup id="TrademarkSign">™</sup>
            <span className="PageHeadlineFlag"></span>
          </div>
          {region.introduction ? (
            <div>
              <IntroText text={region.introduction} id={region._id} />
            </div>
          ) : null}
        </div>
        <div>
          <SecondaryButton text="Request Information" link="/starter" />
        </div>
        {region.destinationName && region.destination ? (
          <BackButton
            id="RegionBackButton"
            text={`${region.destinationName}`}
            link={`/destinations/${region.destinationName}/${region.destination}`}
          />
        ) : (
          <BackButton
            id="RegionBackButton"
            text="All Destinations"
            link="/destinations"
          />
        )}
        {regionAccommodations.length ||
        regionRestaurantsAndPubs.length ||
        regionThingsToDo.length ? (
          <>
          <div id="JumpToCoursesButton" style={{marginTop: "20px" , marginBottom: "-5px"}}>
            <ScrollToIDButton
              text="Jump to"
              idToScrollTo="RegionCoursesSection"
              category="Courses"
            />
            </div>
            <div className="row CardContainer">
              {regionAccommodations.length ? (
                <div className="col-xs-1 sm-2 md-3 lg-4">
                  {region.addThe ? (
                    <CategoryCard
                      categoryName="Places to Stay"
                      categoryURLText="placestostay"
                      destinationName={region.destinationName}
                      regionName={region.regionName}
                      id={region._id}
                      thumbnail={region.placesToStayThumbnail}
                      buttonText={`in the ${region.regionName}`}
                    />
                  ) : (
                    <CategoryCard
                      categoryName="Places to Stay"
                      categoryURLText="placestostay"
                      destinationName={region.destinationName}
                      regionName={region.regionName}
                      id={region._id}
                      thumbnail={region.placesToStayThumbnail}
                      buttonText={`in ${region.regionName}`}
                    />
                  )}
                </div>
              ) : null}
              {regionRestaurantsAndPubs.length ? (
                <div className="col-xs-1 sm-2 md-3 lg-4">
                  <CategoryCard
                    categoryName="Restaurants and Pubs"
                    categoryURLText="restaurantsandpubs"
                    destinationName={region.destinationName}
                    regionName={region.regionName}
                    id={region._id}
                    thumbnail={region.pubsAndRestaurantsThumbnail}
                    buttonText="The Best 19th Holes"
                  />
                </div>
              ) : null}
              {regionThingsToDo.length ? (
                <div className="col-xs-1 sm-2 md-3 lg-4">
                  <CategoryCard
                    categoryName="Things to Do"
                    categoryURLText="thingstodo"
                    destinationName={region.destinationName}
                    regionName={region.regionName}
                    id={region._id}
                    thumbnail={region.thingsToDoThumbnail}
                    buttonText="Sightseeing and More"
                  />
                </div>
              ) : null}
            </div>
          </>
        ) : null}
        {regionCourses.length ? (
          <>
            <div className="SuppliersSection" id="RegionCoursesSection">
              <div className="PageSubHeadline">
                {region.regionName} Golf Courses
              </div>
              <div className="row" id="SortingButtonsRow">
                <div className="SortingButton">
                  <SecondaryButton
                    onClick={sortCoursesAlphabetically}
                    text="Alphabetical"
                  />
                </div>
                <div className="SortingButton">
                  <SecondaryButton
                    onClick={sortCoursesByRating}
                    text="Rating"
                  />
                </div>
              </div>
              <RatingInfo id={region._id} />
              {!ratingSort ? (
                <div className="row CardContainer">
                  {regionCourses
                    // .sort((a, b) => b.rating - a.rating)
                    .map((course) => (
                      <div className="col-xs-1 sm-2 md-3 lg-4" key={course._id}>
                        <CourseCard
                          shortName={course.shortName}
                          thumbnail={course.thumbnail}
                          destinationName={region.destinationName}
                          regionName={course.region.regionName}
                          courseName={course.courseName}
                          rating={course.rating}
                          bucketExperience={course.bucketExperience}
                          id={course._id}
                          buttonText={`Go to ${course.shortName}`}
                        />
                      </div>
                    ))}
                </div>
              ) : (
                <div className="row CardContainer">
                  {coursesSortedByRating
                    // .sort((a, b) => b.rating - a.rating)
                    .map((course) => (
                      <div className="col-xs-1 sm-2 md-3 lg-4" key={course._id}>
                        <CourseCard
                          shortName={course.shortName}
                          thumbnail={course.thumbnail}
                          destinationName={region.destinationName}
                          regionName={course.region.regionName}
                          courseName={course.courseName}
                          rating={course.rating}
                          bucketExperience={course.bucketExperience}
                          id={course._id}
                          buttonText={`Go to ${course.shortName}`}
                        />
                      </div>
                    ))}
                </div>
              )}
            </div>
            {regionCourses.length > 4 ? (
              <>
                <div className="SectionScrollButton">
                  <ScrollToIDButton
                    text="Back to"
                    idToScrollTo="RegionCoursesSection"
                    category="Courses"
                  />
                </div>
              </>
            ) : null}
          </>
        ) : null}
      </div>
      {regionCourses.length ? (
        <>
          <BackToTopButton />
        </>
      ) : null}
      <SubscribeForm />
    </>
  );
};

export default Region;
