import React from "react";
import { useLoaderData } from "react-router-dom";
import SecondaryButton from "../../../../Components/Buttons/SecondaryButton/SecondaryButton";
import BackToTopButton from "../../../../Components/Buttons/ScrollButtons/BackToTopButton/BackToTopButton";
import BackButton from "../../../../Components/Buttons/BackButton/BackButton";
import AccommodationCard from "../../../../Components/Cards/AccommodationCard/AccommodationCard";
import SubscribeForm from "../../../../Components/SubscribeForm/SubscribeForm";
import FlagIcon from "../../../../Assets/FlagIconLarger.png";

export const PlacesToStayLoader = async ({ params }) => {
  try {
    const [placesToStay, region] = await Promise.all([
      fetch(`/api/accommodations/?region=${params.id}`).then((res) =>
        res.json()
      ),
      fetch(`/api/regions/${params.id}`).then((res) => res.json()),
    ]);
    return {
      placesToStay,
      region,
    };
  } catch (error) {
    console.log("Fetch error:", error);
  }
};
const PlacesToStay = () => {
  const { placesToStay, region } = useLoaderData();

  return (
    <>
      <div>
      <div className="NavigationBufferZone"></div>
        <div className="PageHeadline">{region.regionName} Accommodations</div>
        <div>
          <SecondaryButton text="Request Information" link="/starter" />
        </div>
          <BackButton
            text={`${region.regionName}`}
            link={`/destinations/${region.destinationName}/${region.regionName}/${region._id}`}
          />
        {placesToStay.length ? (
          <>
            <p className="MainText" style={{ textAlign: "center", marginTop: "20px" }}>
              <img
                src={FlagIcon}
                alt="GGOG Flag Icon"
                id="RatingFlag"
                style={{
                  height: "25px",
                  width: "25px",
                  marginLeft: "2px",
                  marginRight: "2px",
                  marginBottom: "5px",
                }}
              />
              after star rating indicates course on site or adjacent
            </p>
            <div className="row CardContainer">
              {placesToStay
                // .sort((a, b) => b.rating - a.rating)
                .map((accommodation) => (
                  <div
                    className="col-xs-1 sm-2 md-3 lg-4"
                    key={accommodation._id}
                  >
                    <AccommodationCard
                      shortName={accommodation.shortName}
                      thumbnail={accommodation.thumbnail}
                      destinationName={region.destinationName}
                      regionName={region.regionName}
                      accommodationName={accommodation.accommodationName}
                      rating={accommodation.rating}
                      nearCourse={accommodation.nearCourse}
                      id={accommodation._id}
                      buttonText={`Check out ${accommodation.shortName}`}
                    />
                  </div>
                ))}
            </div>
          </>
        ) : null}
        {placesToStay.length > 4 ? <BackToTopButton /> : null}
        <SubscribeForm />
      </div>
    </>
  );
};

export default PlacesToStay;
