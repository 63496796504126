import React, {} from "react";

const Alert = ({ message, type}) => {

  return (
    <>
      {message && message.trim() !== "" && (
        <div className={`alert alert-${type || "primary"}`} role="alert">
          {message}
        </div>
      )}
    </>
  );
};

export default Alert;