import React, { useState, useEffect } from "react";
import "./HomeText.css";
import "../TextComponents.css";
import FlagIconLarger from "../../../Assets/FlagIconLarger.png";
import SecondaryButton from "../../Buttons/SecondaryButton/SecondaryButton";
import { CSSTransition } from "react-transition-group";

const HomeText = () => {
  const [showMore, setShowMore] = useState(() => {
    const showAllHomeText = sessionStorage.getItem("showAllHomeText");
    return JSON.parse(showAllHomeText) ?? false;
  });

  useEffect(() => {
    sessionStorage.setItem("showAllHomeText", JSON.stringify(showMore));
  }, [showMore]);

  const readMore = () => {
    setShowMore(true);
    sessionStorage.setItem("showAllHomeText", JSON.stringify(showMore));
  };

  return (
    <div>
      <div className="row">
        <div className="col-sm-12" id="WelcomeLine1">
          Welcome to<span id="GGOGText">GGOG</span>
          <img id="FlagIconWelcome" src={FlagIconLarger} alt="GGOG Flag Icon" />
          <sup id="RegisteredSign">™</sup>!
        </div>

        <div className="col-sm-12" id="WelcomeLine2">
          <span id="HomeofGolfTravelText">
            The Home of Golf Travel
            <sup id="TrademarkSign">™</sup>
          </span>{" "}
        </div>
      </div>
      <div>
        <CSSTransition
          // nodeRef={nodeRef}
          in={showMore}
          timeout={500}
          classNames="my-node"
          onEnter={() => setShowMore(true)}
          onExited={() => setShowMore(false)}
        >
          <>
            {!showMore && (
              <>
                <div className="MainTextDesktop">
                <p className="MainText">
                  The greatest aspect of the Great Game of Golf™ is to many
                  (like us!) the combination of golf and travel! But where can
                  you find the good service that you need to help put together
                  the perfect trip? You've found the place. At GGOG™, our
                  mission is helping you see the world, together with your clubs
                  and your favorite golfing companions, while providing the
                  highest levels of service in the industry. We have reinvented
                  the “tour operator experience”... &nbsp;
                  <div onClick={readMore}>
                  <SecondaryButton text="Keep Reading" />
                </div>

                </p>
                </div>
                <div className="MainTextMobile">
                <p className="MainText">
                  The greatest aspect of the Great Game of Golf™ is to many
                  (like us!) the combination of golf and travel! But where can
                  you find the good service that you need to help put together
                  the perfect trip? You've found the place... &nbsp;
                  <div onClick={readMore}>
                  <SecondaryButton text="Keep Reading" />
                </div>
                </p>
                </div>
              </>
            )}
            {showMore && (
              <>
                <p className="MainText">
                  The greatest aspect of the Great Game of Golf™ is to many
                  (like us!) the combination of golf and travel! But where can
                  you find the good service that you need to help put together
                  the perfect trip? You've found the place. At GGOG™, our
                  mission is helping you see the world, together with your clubs
                  and your favorite golfing companions, while providing the
                  highest levels of service in the industry. We have reinvented
                  the “tour operator experience”. Everything we do is for your
                  benefit. We are your advocate—our only goal is to plan the
                  perfect trip for you. Having planned thousands upon thousands
                  of golf trips around the world, you have the confidence of
                  knowing that if we are signing off on a golf trip itinerary,
                  it is a good one! We look at the entirety of each day, not
                  just the tee time. What time do you have to wake up. How much
                  time is an available for breakfast. How long to allow to get
                  to the course. What sightseeing or other activities should you
                  include. And so on. When you get home, we want you to say it
                  was the Best Trip Ever! We look forward to hearing from you
                  and getting the process started! Thank you for your
                  consideration! &nbsp;
                  <div>
                  <SecondaryButton link="/starter" text="Request Information" />
                </div>
                </p>
              </>
            )}
          </>
        </CSSTransition>
      </div>
    </div>
  );
};

export default HomeText;
