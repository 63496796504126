import React from "react";
import SectionText from "../../Components/TextComponents/SectionText/SectionText";
import BackToTopButton from "../../Components/Buttons/ScrollButtons/BackToTopButton/BackToTopButton";
import BulletPoint from "../../Components/BulletPoint/BulletPoint";
import SecondaryButton from "../../Components/Buttons/SecondaryButton/SecondaryButton";
import MainImageButton from "../../Components/Buttons/MainImageButton/MainImageButton";
import "./About.css";
import AboutGGOGMobile from "./AboutAssets/AboutGGOGMobile.webp";
import AboutGGOGDesktop from "./AboutAssets/AboutGGOG.webp";
import Logo from "../../Assets/Logo.png";
import SubscribeForm from "../../Components/SubscribeForm/SubscribeForm";

const About = () => {
  window.scrollTo({
    top: 0,
    left: 0,
    behavior: "instant",
  });

  return (
    <div>
      <div id="About">
        <div className="MainImageContainer">
          <img
            className="MainImage"
            src={AboutGGOGDesktop}
            alt="About GGOG Great Game of Golf"
          />
          <div className="MainImageText" id="AboutText">
            About
          </div>
          <img id="AboutLogo" src={Logo} alt="GGOG Great Game of Golf Logo" />
          <div className="MainImageButtonContainer" id="AboutMainImageButton">
            <MainImageButton text="Start Planning a Trip" link="/starter" />
          </div>
        </div>
        <div className="MainImageContainerMobile">
          <img
            className="MainImage"
            src={AboutGGOGMobile}
            alt="About GGOG Great Game of Golf"
          />
          <div className="MainImageText" id="AboutText">
            About
          </div>
          <img id="AboutLogo" src={Logo} alt="GGOG Great Game of Golf Logo" />
          <div className="MainImageButtonContainer">
            <MainImageButton text="Start Planning a Trip" link="/starter" />
          </div>
        </div>
        <div className="PageHeadline">
          About GGOG<sup className="PageHeadlineTMSign">™</sup>
          <span className="PageHeadlineFlag"></span>
        </div>
        <div>
          <SectionText
            text="Welcome to GGOG™ (Great Game of Golf™)! Our business is the culmination 
            of more than two decades spent planning the world’s finest golf trips—over 5000 
            of them in total, for more than 25000 golfers. We are uniquely positioned to provide you 
            with an uncommon trifecta: product knowledge, decades of experience, and most importantly, 
            good service, that rarest of commodities.  It's the golf tour business reimagined, and 
            the most customer-friendly model in the industry.  You will have more input as to the 
            kind of trip that you want, more understanding of the options, and more control over the 
            choices, with our extensive experience guiding you throughout. We treat your trip, like
            it's our trip.™ Because it is!  The result is a level of trust from our clients, and an 
            overall client experience, that is simply unmatched."
          />

          <div className="PageSubHeadline">How We Plan Your Trip</div>
          <SectionText
            text="It all begins when you “Visit The Starter™” and submit our Starter form.
            We then schedule a phone call, a Zoom call, or simply exchange texts or emails,
            whatever works best for you.  Once we establish the parameters of your trip
            (destination, group size, length, dates, special requirements, etc.), we start
            working on a proposed itinerary.  We can quote all sorts of different
            accommodations options and pricing, so that you can make the best decisions for
            your group.  We ask you the “overlooked” questions. How much daily driving time
            would be acceptable?  What else do you want to do besides playing golf?  Perhaps
            just as importantly, what time do you want to wake up in the morning?  Because a
            9:00 AM tee time can be a lot earlier than it sounds if you want to enjoy
            breakfast, have to travel to the course, and need time to warmup before the
            round.  Then, once you give us the go-ahead, we confirm the schedule for you,
            making changes as needed depending on the availability, all subject to your
            approval.  Your final confirmed itinerary includes more than just your tee times.
            It includes suggested wakeup times, suggested departure times, allowance for time
            after the round to fully enjoy the club you are visiting.  It will include
            suggested sightseeing and dining.  Most importantly, it will make sure you are
            not “over”-scheduled.  There’s only so much you can do and still have a relaxing
            and enjoyable trip.  If something besides golf is suggested, you can be sure that
            1) it’s worth including and 2) there’s plenty of time to include it!
"
          />
          <SecondaryButton link="/starter" text="Visit The Starter™" />
          <br />

          <div className="PageSubHeadline">Frequently Asked Questions</div>
          <ul className="BenefitBulletPointsContainer">
            <div className="ClickToLearnMore">
              Click any question to learn more!
            </div>
            <BulletPoint
              headline="What are the differences between a GGOG™ tour and others?"
              text="Better itineraries that are backed by more than two decades of experience, 
              and are centered around what our clients actually want.  More for your money in 
              terms of quality inclusions.  Daily schedules that are planned from start to 
              finish to make sure that you don’t end up needing a “vacation from your vacation” 
              when you get home.  Greater flexibility in terms of changes or cancellations. We
              could go on and on!"
            />

            <BulletPoint
              headline="Does one person in the group have to make payments for everyone?"
              text="Generally, it is easier to collect just one payment for the group.  However,
              we will facilitate individual payments as needed or requested."
            />

            <BulletPoint
              headline="Do you offer additional local destination assistance?"
              text="When requested, yes.  Accommodations, drivers, and golf courses are 
              all available for assistance though, and it's unlikely that you would be unable to 
              handle a situation directly.  In our over two decades of planning tours both in the 
              USA and around the world, we have never once experienced a 'true emergency' requiring 
              our involvement.  You could always be the first, but that would be very unlucky indeed!"
            />

            <BulletPoint
              headline="What if we end up having to make changes or cancel?"
              text="We'll facilitate one set of changes per trip at no additional charge, including
              adding up to five additional days.  In the event of cancellation, we do not have a
              blanket “non-refundable” policy.  All cancellations are subject to the policies of the
              various golf courses, accommodations, transportation suppliers, etc.  However, we will
              try and get as much money back as possible for your group, or, in the event refunds
              from the various suppliers are not possible, to obtain future credits.  Please note
              that refunds and credits are subject to modest administration fees."
            />
          </ul>

          <div className="PageSubHeadline">Summary</div>

          <SectionText
            text="At GGOG™, our mission is to plan the perfect trip for your group.  Part of the way
            we deliver that is by doing things other companies won’t.  We handle reasonable change
            or rescheduling requests at no additional charge.  We provide actual confirmations.  We
            allow other provisions for obtaining refunds besides travel insurance (please note that
              travel insurance is still highly recommended).  At GGOG™, we will not let anything get
              in the way of having the most positive relationships possible with our valued clients!
              We hope that you contact us and get the ball rolling on what promises to be both the
              trip of a lifetime, and a seamless, trustworthy experience.  Thank you for your
              consideration!
            "
          />
          <BackToTopButton />
          <SecondaryButton link="/starter" text="Contact Us" />
        </div>

        <SubscribeForm />
      </div>
    </div>
  );
};

export default About;
