import React from "react";
import { Link } from "react-router-dom";
import BackFlag from "../../../Assets/FlagIconTransparentReversed.png";
import "./BackButton.css";

const BackButton = ({ text, link }) => {
  return (
      <Link to={link}>
        <button className="BackButton"><img id="BackButtonFlag" src={BackFlag} alt="GGOG Flag Back Button" />{text}</button>
      </Link>
  );
};

export default BackButton;
