import axios from "axios";

// eslint-disable-next-line
export default {
  createDestination: function (DestinationData) {
    return axios.post("/api/destinations/", DestinationData);
  },

  getAllDestinations: function () {
    return axios.get("/api/destinations/");
  },

  getDestination: function (id) {
    return axios.get("/api/destinations/" + id);
  },

  updateDestination: function (id, destination) {
    return axios.put("/api/destinations/" + id, destination);
  },

  deleteDestination: function (id) {
    return axios.delete("/api/destinations/" + id);
  },

  getRegion: function (id) {
    return axios.get("/api/regions/" + id);
  },

  createMember: function (CourseData) {
    return axios.post("/api/courses/", CourseData);
  },

  getAllCourses: function (destination) {
    return axios.get(
      "/api/courses?destination=" + destination);
  },

  getRegionCourses: function (destination, region) {
    return axios.get(
      "/api/courses?destination=" + destination + "?region=" + region);
  },

  getRegionCoursesWithoutDestination: function (region) {
    return axios.get(
      "/api/courses?region=" + region);
  },

  getCourse: function (id) {
    return axios.get("/api/courses/" + id);
  },

  updateCourse: function (id) {
    return axios.put("/api/courses/" + id);
  },

  deleteCourse: function (id) {
    return axios.delete("/api/courses/" + id);
  },

  getAllMembers: function (destination, category) {
    return axios.get(
      "/api/members?destination=" + destination + "&category=" + category
    );
  },

  getMember: function (id) {
    return axios.get("/api/members/" + id);
  },

  updateMember: function (id, member) {
    return axios.put("/api/courses/" + id, member);
  },

  deleteMember: function (id) {
    return axios.delete("/api/members/" + id);
  },

  createRequest: function (RequestData) {
    return axios.post("/api/requests/", RequestData);
  },

  createSubscribe: function (SubscribeData) {
    return axios.post("/api/subscribes/", SubscribeData);
  },
};
