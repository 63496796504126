import React, {useState} from "react";
import "./BulletPoint.css";

const BulletPoint = ({headline, text}) => {
  const [showDetails, setShowDetails] = useState(false);

  const toggleDetails = () => {
    setShowDetails(!showDetails);
  };
  return (
    <div>
      {!showDetails && (
        <li id="BulletPointHeadline" onClick={toggleDetails}>
          {headline}
        </li>
      )}

      {showDetails && (
        <>
          <li
            id="BulletPointHeadline"
            onClick={toggleDetails}
          >
            {headline}
          </li>
          <p id="BulletPointText">{text}</p>
        </>
      )}
    </div>
  );
};

export default BulletPoint;
