import React from 'react';
import { Link } from "react-router-dom";
import "./MainImageButton.css";

const MainImageButton = ({text, link, onSubmit}) => {
    return (
        <div>
        <Link to={link}>
          <button onClick={onSubmit}className="MainImageButton">{text}</button>
        </Link>
      </div>
    );
};

export default MainImageButton;