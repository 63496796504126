import React, { useState, useEffect, createRef, useRef } from "react";
import { useNavigate } from "react-router-dom";
import "./Starter.css";
import MainImageButton from "../../Components/Buttons/MainImageButton/MainImageButton";
import SecondaryImageButton from "../../Components/Buttons/SecondaryButton/SecondaryButton";
import Alert from "../../Components/Alert/Alert";
import SectionText from "../../Components/TextComponents/SectionText/SectionText";
// import GGOGStarterPhoto from "../../Assets/GGOGStarterPhoto.png";
import GGOGStarter from "./StarterAssets/GGOGStarterMobile.webp";
import GGOGStarterDesktop from "./StarterAssets/GGOGStarterDesktop.webp";
import Logo from "../../Assets/Logo.png";
import API from "../../utils/API";

const Starter = () => {
  const [starterForm, setStarterForm] = useState({
    firstName: "",
    lastName: "",
    emailAddress: "",
    phoneNumber: "",
    details: "",
    destination: "",
    subscribe: true,
  });

  const inputRef = useRef(null);

  const navigate = useNavigate();

  const [showAlert, setShowAlert] = useState(false);
  const [showText, setShowText] = useState(true);
  const [showSubmitted, setShowSubmitted] = useState(false);
  const [contactSuccess, setContactSuccess] = useState(true);

  const recaptchaRef = createRef();

  const SITE_KEY = process.env.REACT_APP_reCAPTCHA_SITE_KEY;

  const onSuccess = () => {
    console.log("Hey contact form success");
    setStarterForm({
      firstName: "",
      lastName: "",
      emailAddress: "",
      phoneNumber: "",
      details: "",
      destination: "",
      subscribe: true,
    });
    setShowSubmitted(true);
    setShowText(false);
    const timer = setTimeout(() => {
      navigate("/thankyou");
    }, 2000);
    return () => clearTimeout(timer);
  };

  const onFailure = () => {
    console.log("Hey contact form failure");
    setShowSubmitted(true);
    setContactSuccess(false);
    setShowText(false);
    const timer = setTimeout(() => {
      setShowSubmitted(false);
      setContactSuccess(true);
      setShowText(true);
    }, 3000);
    return () => clearTimeout(timer);
  };

  const emailAddress = starterForm.emailAddress;

  const validateEmail = (emailAddress) => {
    return /\S+@\S+\.\S+/.test(emailAddress);
  };

  const checkFields = () => {
    if (
      !starterForm.firstName ||
      !starterForm.lastName ||
      !starterForm.phoneNumber ||
      !starterForm.emailAddress ||
      !validateEmail(emailAddress)
    ) {
      setShowAlert(true);
      setShowText(false);
      const timer = setTimeout(() => {
        setShowAlert(false);
        setShowText(true);
      }, 3000);
      return () => clearTimeout(timer);
    }
  };

  useEffect(() => {
    // TODO: Check and see if mobile device. If so, don't add the focus.
    // react-device-detect
    inputRef.current.focus();
    window.scrollTo({
      top: 0,
      left: 0,
      // behavior: "auto",
    });
    // const timer = setTimeout(() => {
    //   document.getElementById("Starter").scrollIntoView({ behavior: "smooth" });
    // }, 5000);

    const loadScriptByURL = (id, url, callback) => {
      const isScriptExist = document.getElementById(id);

      if (!isScriptExist) {
        var script = document.createElement("script");
        script.type = "text/javascript";
        script.src = url;
        script.id = id;
        script.onload = function () {
          if (callback) callback();
        };
        document.body.appendChild(script);
      }

      if (isScriptExist && callback) callback();
    };

    // load the script by passing the URL
    loadScriptByURL(
      "recaptcha-key",
      `https://www.google.com/recaptcha/api.js?render=${SITE_KEY}`,
      function () {
        console.log("Script loaded!");
      }
    );
    // return () => clearTimeout(timer);
  }, [SITE_KEY]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setStarterForm({ ...starterForm, [name]: value });
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    document.getElementById("Starter").scrollIntoView({ behavior: "smooth" });
    checkFields();
    if (
      !starterForm.firstName ||
      !starterForm.lastName ||
      !starterForm.emailAddress ||
      !validateEmail(emailAddress) ||
      !starterForm.phoneNumber
    ) {
      return;
    } else {
      API.createRequest(starterForm)
        .then(() => {
          console.log("a user has been created" + starterForm);
        })
        .catch((err) => {
          throw err;
        });
      console.log("a form has been submitted");
      const token = await window.grecaptcha.execute(SITE_KEY, {
        action: "submit",
      });
      let form = {
        ...starterForm,
        "g-recaptcha-response": token,
      };

      let responsePromises = [];

      console.log(form);

      // Send to Email.

      //  const emailResponse = axios.post(
      //   `${this.props.apiUrl}/contact/submit`,
      //   form
      // );

      // responsePromises.push(emailResponse);

      Promise.all(responsePromises).then((responses) => {
        console.log(responses.map((response) => response.data.success));
        console.log(
          responses.map((response) => response.data.success)
          // .includes((success) => success === false)
        );
        // If any of our responses have success = false, then fail the form. Otherwise, succeed the form.
        if (
          responses.map((response) => response.data.success).includes(false)
        ) {
          onFailure();
        }
        onSuccess();
      });
    }
  };

  const toggleSubscribe = () => {
    setStarterForm({ ...starterForm, subscribe: !starterForm.subscribe });
  };

  return (
    <>
      <div className="MainImageContainer" id="StarterImageContainer">
        <img
          className="MainImage"
          id="StarterImage"
          src={GGOGStarterDesktop}
          alt="About GGOG Great Game of Golf"
        />
        <div id="StarterText" className="MainImageText">
          The Starter<span className="MainImageTMSign">™</span>
        </div>
        <img id="StarterLogo" src={Logo} alt="GGOG Great Game of Golf Logo" />
        <div id="ScrollToPoint"></div>
      </div>
      <div className="MainImageContainer" id="StarterImageContainerMobile">
        <img
          className="MainImage"
          id="StarterImage"
          src={GGOGStarter}
          alt="About GGOG Great Game of Golf"
        />
        <div id="StarterText" className="MainImageText">
          The Starter<span className="MainImageTMSign">™</span>
        </div>
        <img id="StarterLogo" src={Logo} alt="GGOG Great Game of Golf Logo" />
      </div>
      <div id="Starter">
        <div id="StarterHeadline" className="PageHeadline">
          The GGOG<sup id="TrademarkSign">™</sup>
          <span className="PageHeadlineFlag"></span>&nbsp;Starter
          <sup id="TrademarkSign">™</sup>
        </div>
        <div id="StarterSubHeadline" className="PageSubHeadline">Enquire Now!</div>
        {showSubmitted ? (
          contactSuccess ? (
            <Alert
              message="Your message was sent successfully!"
              type="success"
            />
          ) : (
            <Alert
              message="There was an issue submitting your message, please email us 
              instead at greatgameofgolf@gmail.com"
              type="danger"
            />
          )
        ) : null}
        {showAlert && !starterForm.firstName ? (
          <Alert message="Please enter your first name" type="danger" />
        ) : null}
        {showAlert && !starterForm.lastName ? (
          <Alert message="Please enter your last name" type="danger" />
        ) : null}
        {showAlert && !starterForm.emailAddress ? (
          <Alert message="Please enter your email address" type="danger" />
        ) : null}
        {showAlert &&
        starterForm.emailAddress &&
        !validateEmail(emailAddress) ? (
          <Alert
            message="Please check your email address format"
            type="danger"
          />
        ) : null}
        {showAlert && !starterForm.phoneNumber ? (
          <Alert message="Please enter your phone number" type="danger" />
        ) : null}
        {showText && <SectionText text="This is where it all begins!  Just fill out
        and submit this quick form, and you're next up on the tee. We will get 
        back to you quickly, and look forward to helping you plan the perfect trip!"/>}
        <form
          id="StarterFormDesktop"
          className="contact-form"
          onSubmit={handleFormSubmit}
        >
          <div className="row">
            <div className="col-lg-6 form-group form-group--firstName">
              <label className="StarterFieldTitle" htmlFor="starter-firstName">
                First Name <span className="required">*</span>
              </label>
              <input
                ref={inputRef}
                onChange={handleInputChange}
                className="form-control"
                type="text"
                name="firstName"
                value={starterForm.firstName}
                id="contact-firstName"
                required
              />
            </div>
            <div className="col-lg-6 form-group form-group--lastName">
              <label className="StarterFieldTitle" htmlFor="starter-lastName">
                Last Name <span className="required">*</span>
              </label>
              <input
                onChange={handleInputChange}
                className="form-control"
                type="text"
                name="lastName"
                value={starterForm.lastName}
                id="contact-lastName"
                required
              />
            </div>
            <div className="col-lg-6 form-group form-group--email">
              <label className="StarterFieldTitle" htmlFor="starter-email">
                Email Address <span className="required">*</span>
              </label>
              <input
                onChange={handleInputChange}
                className="form-control"
                type="email"
                name="emailAddress"
                value={starterForm.emailAddress}
                id="contact-email"
                required
              />
            </div>
            <div className="col-lg-6 form-group form-group--email">
              <label className="StarterFieldTitle" htmlFor="starter-phone">
                Phone Number <span className="required">*</span>
              </label>
              <input
                onChange={handleInputChange}
                className="form-control"
                type="text"
                name="phoneNumber"
                value={starterForm.phoneNumber}
                id="contact-phone"
                required
              />
            </div>
            <div className="col-12 form-group form-group--subject">
              <label
                className="StarterFieldTitle"
                htmlFor="starter-destination"
              >
                Destination
              </label>
              <select
                onChange={handleInputChange}
                className="form-control"
                type="text"
                name="destination"
                value={starterForm.destination}
                id="starter-destination"
              >
                <option value="" disabled>
                  Select from dropdown
                </option>
                <option value="Scotland">Scotland</option>
                <option value="Ireland">Ireland/Northern Ireland</option>
                <option value="England">England</option>
                <option value="Wales">Wales</option>
                <option value="California">California/Pebble Beach</option>
                <option value="Canada">Canada</option>
                <option value="Arizona">Arizona</option>
                <option value="Dominican">Dominican Republic</option>
                <option value="Hawaii">Hawaii</option>
                <option value="Michigan">Michigan</option>
                <option value="trip">
                  Other--please provide details below
                </option>
              </select>
            </div>
            <div className="col-12 form-group form-group--message">
              <label
                className="StarterFieldTitle"
                value={starterForm.details}
                htmlFor="starter-details"
              >
                Any extra details about your plans{" "}
              </label>
              <textarea
                onChange={handleInputChange}
                className="form-control"
                name="details"
                value={starterForm.details}
                id="starter-details"
                required
              ></textarea>
            </div>
            <div className="col-12 form-group form-group--subscribe">
              <input
                className="checkbox-boolean"
                type="checkbox"
                name="subscribeconsent"
                id="starter-subscribe"
                onClick={toggleSubscribe}
                defaultChecked={starterForm.subscribe}
              />
              <label className="SubscribeText" htmlFor="contact-subscribe">
                &nbsp;Yes, I would like to receive emails from GGOG™
              </label>
            </div>
            <div className="col-12">
              <div className="SubmitButtonContainer">
                <MainImageButton
                  id="StarterSubmitButton"
                  className="StarterFieldTitle"
                  onSubmit={handleFormSubmit}
                  type="submit"
                  text={`Submit your ${starterForm.destination} request!`}
                />
              </div>
            </div>
            <div className="col-12">
              <label
                htmlFor="starter-recaptcha"
                style={{ marginTop: "0.5rem" }}
              >
                <small>
                  This site is protected by reCAPTCHA and the Google{" "}
                  <a
                    href="https://policies.google.com/privacy"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Privacy&nbsp;Policy
                  </a>{" "}
                  and{" "}
                  <a
                    href="https://policies.google.com/terms"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Terms&nbsp;of&nbsp;Service
                  </a>{" "}
                  apply.
                </small>
              </label>
            </div>
          </div>
          <div
            className="g-recaptcha"
            data-sitekey={SITE_KEY}
            data-size="invisible"
            ref={recaptchaRef}
          />
        </form>
        <form
          id="StarterFormMobile"
          className="contact-form"
          onSubmit={handleFormSubmit}
        >
          <div className="row">
            <div className="col-lg-6 form-group form-group--firstName">
              <label className="StarterFieldTitle" htmlFor="starter-firstName">
                First Name <span className="required">*</span>
              </label>
              <input
                onChange={handleInputChange}
                className="form-control"
                type="text"
                name="firstName"
                value={starterForm.firstName}
                id="contact-firstName"
                required
              />
            </div>
            <div className="col-lg-6 form-group form-group--lastName">
              <label className="StarterFieldTitle" htmlFor="starter-lastName">
                Last Name <span className="required">*</span>
              </label>
              <input
                onChange={handleInputChange}
                className="form-control"
                type="text"
                name="lastName"
                value={starterForm.lastName}
                id="contact-lastName"
                required
              />
            </div>
            <div className="col-lg-6 form-group form-group--email">
              <label className="StarterFieldTitle" htmlFor="starter-email">
                Email Address <span className="required">*</span>
              </label>
              <input
                onChange={handleInputChange}
                className="form-control"
                type="email"
                name="emailAddress"
                value={starterForm.emailAddress}
                id="contact-email"
                required
              />
            </div>
            <div className="col-lg-6 form-group form-group--email">
              <label className="StarterFieldTitle" htmlFor="starter-phone">
                Phone Number <span className="required">*</span>
              </label>
              <input
                onChange={handleInputChange}
                className="form-control"
                type="text"
                name="phoneNumber"
                value={starterForm.phoneNumber}
                id="contact-phone"
                required
              />
            </div>
            <div className="col-12 form-group form-group--subject">
              <label
                className="StarterFieldTitle"
                htmlFor="starter-destination"
              >
                Destination
              </label>
              <select
                onChange={handleInputChange}
                className="form-control"
                type="text"
                name="destination"
                value={starterForm.destination}
                id="starter-destination"
              >
                <option value="" disabled>
                  Select from dropdown
                </option>
                <option value="Scotland">Scotland</option>
                <option value="Ireland">Ireland/Northern Ireland</option>
                <option value="England">England</option>
                <option value="Wales">Wales</option>
                <option value="California">California/Pebble Beach</option>
                <option value="Canada">Canada</option>
                <option value="Arizona">Arizona</option>
                <option value="Dominican">Dominican Republic</option>
                <option value="Hawaii">Hawaii</option>
                <option value="Michigan">Michigan</option>
                <option value="trip">
                  Other--please provide details below
                </option>
              </select>
            </div>
            <div className="col-12 form-group form-group--message">
              <label
                className="StarterFieldTitle"
                value={starterForm.details}
                htmlFor="starter-details"
              >
                Any extra details about your plans{" "}
              </label>
              <textarea
                onChange={handleInputChange}
                className="form-control"
                name="details"
                value={starterForm.details}
                id="starter-details"
                required
              ></textarea>
            </div>
            <div className="col-12 form-group form-group--subscribe">
              <input
                className="checkbox-boolean"
                type="checkbox"
                name="subscribeconsent"
                id="starter-subscribe"
                onClick={toggleSubscribe}
                defaultChecked={starterForm.subscribe}
              />
              <label className="SubscribeText" htmlFor="contact-subscribe">
                &nbsp;Yes, I would like to receive emails from GGOG™
              </label>
            </div>
            <div className="col-12">
              <div className="SubmitButtonContainer">
                <MainImageButton
                  id="StarterSubmitButton"
                  className="StarterFieldTitle"
                  onSubmit={handleFormSubmit}
                  type="submit"
                  text={`Submit your ${starterForm.destination} request!`}
                />
              </div>
            </div>
            <div id="SubmitButtonContainerMobile" className="col-12">
              <SecondaryImageButton
                className="StarterFieldTitle"
                onClick={handleFormSubmit}
                type="submit"
                text={`Submit your request!`}
              />
            </div>
            <div className="col-12">
              <label
                htmlFor="starter-recaptcha"
                style={{ marginTop: "0.5rem" }}
              >
                <small>
                  This site is protected by reCAPTCHA and the Google{" "}
                  <a
                    href="https://policies.google.com/privacy"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Privacy&nbsp;Policy
                  </a>{" "}
                  and{" "}
                  <a
                    href="https://policies.google.com/terms"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Terms&nbsp;of&nbsp;Service
                  </a>{" "}
                  apply.
                </small>
              </label>
            </div>
          </div>
          <div
            className="g-recaptcha"
            data-sitekey={SITE_KEY}
            data-size="invisible"
            ref={recaptchaRef}
          />
        </form>
      </div>
      <div className="MainImageContainer" id="StarterImageContainerMobileBottom">
        <img
          className="MainImage"
          id="StarterImage"
          src={GGOGStarter}
          alt="About GGOG Great Game of Golf"
        />
        <div id="StarterText" className="MainImageText">
          The Starter<span className="MainImageTMSign">™</span>
        </div>
        <img id="StarterLogo" src={Logo} alt="GGOG Great Game of Golf Logo" />
      </div>
    </>
  );
};

export default Starter;
