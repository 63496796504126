import React, { useState, useEffect } from "react";
import { useLoaderData } from "react-router-dom";
import MainImageButton from "../../../Components/Buttons/MainImageButton/MainImageButton";
import SecondaryButton from "../../../Components/Buttons/SecondaryButton/SecondaryButton";
import BackToTopButton from "../../../Components/Buttons/ScrollButtons/BackToTopButton/BackToTopButton";
import BackButton from "../../../Components/Buttons/BackButton/BackButton";
import ScrollToIDButton from "../../../Components/Buttons/ScrollButtons/ScrollToIDButton/ScrollToIDButton";
import CourseCard from "../../../Components/Cards/CourseCard/CourseCard";
import RegionCard from "../../../Components/Cards/RegionCard/RegionCard";
import AccommodationCard from "../../../Components/Cards/AccommodationCard/AccommodationCard";
import SubscribeForm from "../../../Components/SubscribeForm/SubscribeForm";
import RatingInfo from "../../../Components/RatingInfo/RatingInfo";
import IntroText from "../../../Components/TextComponents/IntroText/IntroText";
import FlagIcon from "../../../Assets/FlagIconLarger.png";
import "./DestinationSingle.css";
import "../FeaturePages.css";

export const DestinationSingleLoader = async ({ params }) => {
  try {
    const [
      destination,
      regions,
      destinationCourses,
      destinationAccommodations,
    ] = await Promise.all([
      fetch(`/api/destinations/${params.id}`).then((res) => res.json()),
      fetch(`/api/regions?destination=${params.id}`).then((res) => res.json()),
      fetch(`/api/courses?destination=${params.id}`).then((res) => res.json()),
      fetch(`/api/accommodations?destination=${params.id}&member=true`).then(
        (res) => res.json()
      ),
    ]);
    return {
      destination,
      regions,
      destinationCourses,
      destinationAccommodations,
    };
  } catch (error) {
    console.log("Fetch error:", error);
  }
};

const Destination = () => {
  const {
    destination,
    regions,
    destinationCourses,
    destinationAccommodations,
  } = useLoaderData();

  const coursesByRating = [...destinationCourses];

  const coursesSortedByRating = coursesByRating.sort((a, b) => {
    if (a.rating < b.rating) {
      return 1;
    } else if (a.rating > b.rating) {
      return -1;
    } else {
      if (a.shortName < b.shortName) {
        return -1;
      } else if (a.shortName > b.shortName) {
        return 1;
      } else {
        return 0;
      }
    }
  });

  const [sorted, setSorted] = useState(() => {
    const destinationsSorted = sessionStorage.getItem("destinationsSorted");
    return JSON.parse(destinationsSorted) ?? [];
  });

  const [ratingSort, setRatingSort] = useState(() => {
    const destinationsSorted =
      sessionStorage.getItem("destinationsSorted") ?? [];
    return destinationsSorted.includes(destination._id) ? true : false;
  });

  useEffect(() => {
    sessionStorage.setItem("destinationsSorted", JSON.stringify(sorted));
  }, [sorted, ratingSort]);

  const sortCoursesAlphabetically = () => {
    const sortedAlphabetically = [...sorted];
    sortedAlphabetically.splice(destination._id, 1);
    setSorted(sortedAlphabetically);
    setRatingSort(false);
    sessionStorage.setItem("destinationsSorted", JSON.stringify(sorted));
  };

  const sortCoursesByRating = () => {
    const sortedByRating = [...sorted];
    if (!sortedByRating.includes(destination._id)) {
      sortedByRating.push(destination._id);
    }
    setSorted(sortedByRating);
    setRatingSort(true);
    sessionStorage.setItem("destinationsSorted", JSON.stringify(sorted));
  };

  return (
    <>
      <div>
        <div className="MainImageContainer">
          <img
            className="MainImage"
            src={destination?.mainImage}
            alt={`${destination?.destinationName} Golf Trips`}
          />
          <div className="MainImageText">
            {destination?.destinationName} Golf Trips
          </div>
          <div className="MainImageButtonContainer">
            <MainImageButton text="Start Planning Yours" link="/starter" />
          </div>
        </div>
        <div className="MainImageContainerMobile">
          <img
            className="MainImage"
            src={destination?.mainImageMobile}
            alt={`${destination?.destinationName} Golf Trips`}
          />
          <div className="MainImageText">
            {destination?.destinationName} Golf Trips
          </div>
          <div className="MainImageButtonContainer">
            <MainImageButton text="Start Planning Yours" link="/starter" />
          </div>
        </div>
        <div>
          <div className="PageHeadline">
            {destination?.destinationName} Golf Vacations from GGOG
            <sup id="TrademarkSign">™</sup>
            <span className="PageHeadlineFlag"></span>
          </div>
          <IntroText text={destination.introduction} id={destination._id} />
        </div>
        <div>
          <SecondaryButton text="Request Information" link="/starter" />
        </div>
          <BackButton text="All Destinations" link="/destinations" />
        {regions.length ? (
          <>
            <div id="RegionsSection">
              <div className="PageSubHeadline">
                {destination?.destinationName} Golf Tour Regions
              </div>
              <div className="row CardContainer">
                {regions.map((region) => (
                  <div className="col-xs-1 sm-2 md-3 lg-4" key={region._id}>
                    {region.addThe ? (
                      <RegionCard
                        regionName={region.regionName}
                        destinationName={region.destinationName}
                        id={region._id}
                        thumbnail={region.thumbnail}
                        subtitle={region.subtitle}
                        buttonText={`Visit the ${region.regionName}`}
                      />
                    ) : (
                      <RegionCard
                        regionName={region.regionName}
                        destinationName={region.destinationName}
                        id={region._id}
                        thumbnail={region.thumbnail}
                        subtitle={region.subtitle}
                        buttonText={`Visit ${region.regionName}`}
                      />
                    )}
                  </div>
                ))}
              </div>
            </div>
          </>
        ) : null}
        {regions.length > 1 ? (
          <>
            <div className="SectionScrollButton">
              <ScrollToIDButton
                text="Back to"
                idToScrollTo="RegionsSection"
                category="Regions"
              />
            </div>
          </>
        ) : null}
        {destinationCourses.length ? (
          <>
            <div className="SuppliersSection" id="CoursesSection">
              <div className="PageSubHeadline" id="CoursesSubHeadline">
                {destination?.destinationName} Golf Courses
              </div>
              <div className="row" id="SortingButtonsRow">
                <div className="SortingButton">
                  <SecondaryButton
                    onClick={sortCoursesAlphabetically}
                    text="Alphabetical"
                  />
                </div>
                <div className="SortingButton">
                  <SecondaryButton
                    onClick={sortCoursesByRating}
                    text="Rating"
                  />
                </div>
              </div>
              <RatingInfo id={destination._id} />
              {!ratingSort ? (
                <div className="row CardContainer">
                  {destinationCourses
                    // .sort((a, b) => b.rating - a.rating)
                    .map((course) => (
                      <div className="col-xs-1 sm-2 md-3 lg-4" key={course._id}>
                        <CourseCard
                          shortName={course.shortName}
                          thumbnail={course.thumbnail}
                          destinationName={destination?.destinationName}
                          courseName={course.courseName}
                          rating={course.rating}
                          bucketExperience={course.bucketExperience}
                          id={course._id}
                          buttonText={`Go to ${course.shortName}`}
                        />
                      </div>
                    ))}
                </div>
              ) : (
                <div className="row CardContainer">
                  {coursesSortedByRating
                    // .sort((a, b) => b.rating - a.rating)
                    .map((course) => (
                      <div className="col-xs-1 sm-2 md-3 lg-4" key={course._id}>
                        <CourseCard
                          shortName={course.shortName}
                          thumbnail={course.thumbnail}
                          destinationName={destination?.destinationName}
                          courseName={course.courseName}
                          rating={course.rating}
                          bucketExperience={course.bucketExperience}
                          id={course._id}
                          buttonText={`Go to ${course.shortName}`}
                        />
                      </div>
                    ))}
                </div>
              )}
            </div>
            {coursesSortedByRating.length > 4 ? (
              <>
                <ScrollToIDButton
                  text="Back to"
                  idToScrollTo="CoursesSection"
                  category="Courses"
                />
              </>
            ) : null}
          </>
        ) : null}
        {destinationAccommodations.length ? (
          <>
            <div className="SuppliersSection">
              <div className="PageSubHeadline">
                Places to Stay in {destination?.destinationName}
              </div>
              <p className="MainText" style={{ textAlign: "center" }}>
                <img
                  src={FlagIcon}
                  alt="GGOG Flag Icon"
                  id="RatingFlag"
                  style={{
                    height: "25px",
                    width: "25px",
                    marginLeft: "2px",
                    marginRight: "2px",
                    marginBottom: "5px",
                  }}
                />
                after star rating indicates course on site or adjacent
              </p>
              <div className="row CardContainer">
                {destinationAccommodations
                  // .sort((a, b) => b.rating - a.rating)
                  .map((accommodation) => (
                    <div
                      className="col-xs-1 sm-2 md-3 lg-4"
                      key={accommodation._id}
                    >
                      <AccommodationCard
                        shortName={accommodation.shortName}
                        thumbnail={accommodation.thumbnail}
                        destinationName={destination?.destinationName}
                        regionName={accommodation.region.regionName}
                        accommodationName={accommodation.accommodationName}
                        rating={accommodation.rating}
                        nearCourse={accommodation.nearCourse}
                        id={accommodation._id}
                        buttonText={`Check out ${accommodation.shortName}`}
                      />
                    </div>
                  ))}
              </div>
            </div>
            {destinationAccommodations.length > 4 ? (
              <>
                <ScrollToIDButton
                  text="Back to"
                  idToScrollTo="AccommodationsSection"
                  category="Places to Stay"
                />
                <BackButton text="All Destinations" link="/destinations" />
              </>
            ) : null}
          </>
        ) : null}
        {destinationCourses.length || regions.length ? (
          <>
            <BackToTopButton />
          </>
        ) : null}
      </div>
      <SubscribeForm />
    </>
  );
};

export default Destination;
