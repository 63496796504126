import React from "react";
import { useLoaderData } from "react-router-dom";
import DestinationCard from "../../Components/Cards/DestinationCard/DestinationCard";
import "./DestinationsAll.css";
import BackToTopButton from "../../Components/Buttons/ScrollButtons/BackToTopButton/BackToTopButton";

export const DestinationsLoader = async () => {
  try {
    const res = await fetch("/api/destinations/");
    return res.json();
  } catch (error) {
    console.log("Fetch error:", error);
  }
};

const DestinationsAll = () => {
  const destinations = useLoaderData();

  return (
    <>
      <div id="DestinationsAll">
        <div className="NavigationBufferZone"></div>
        <div className="PageHeadline">
          Where do you want to play?<sup className="PageHeadlineTMSign">™</sup>
        </div>
        <div className="row CardContainer" id="DestinationsPageCardContainer">
          {destinations.map((destination) => (
            <div className="col-xs-1 sm-2 md-3 lg-4" key={destination._id}>
              <DestinationCard
                destinationName={destination.destinationName}
                thumbnail={destination.thumbnail}
                id={destination._id}
                buttonText={`Golf in ${destination.destinationName}`}
              />
            </div>
          ))}
        </div>
      </div>
      <BackToTopButton />
    </>
  );
};

export default DestinationsAll;
