import React from "react";
import CardButton from "../../Buttons/CardButton/CardButton";
import { Link } from "react-router-dom";
import Icon from "../../../Assets/GGOGIconTM.png";
import StarsContainer from "../../RatingContainers/StarsContainer/StarsContainer";
import ComingSoonThumbnail from "../ComingSoonThumbnail.webp";
import "./AccommodationCard.css";
import "../Cards.css";

const AccommodationCard = ({
  shortName,
  accommodationName,
  regionName,
  destinationName,
  thumbnail,
  buttonText,
  rating,
  nearCourse,
  id,
}) => {
  return (
    <Link
      to={`/placestostay/${destinationName}/${regionName}/${accommodationName}/${id}`}
    >
      <div className="TravelCard">
        <div className="GGOGIconContainer">
          <img className="GGOGIcon" src={Icon} alt="GGOG Icon"></img>
        </div>
        <div className="CardContentContainer">
          {thumbnail ? (
            <img
              className="TravelCardThumbnailImage"
              src={thumbnail}
              alt={`${shortName} hotel rooms`}
            />
          ) : (
            <img
              className="TravelCardThumbnailImage"
              src={ComingSoonThumbnail}
              alt={`${shortName} hotel rooms`}
            />
          )}
          <div className="CardName" id="AccommodationName">
            <div>{accommodationName}</div>
            <div className="row" id="RatingStarsContainer">
            <StarsContainer rating={rating} nearCourse={nearCourse} />
          </div>
          </div>
          <CardButton buttonText={buttonText}/>
        </div>
      </div>
    </Link>
  );
};

export default AccommodationCard;
