import React, { useState } from "react";
import "../TextComponents.css";
import { CSSTransition } from "react-transition-group";

const SectionText = ({ text }) => {
  const [FullText, setFullText] = useState(false);

  const shortText = text.substring(0, 500);

  const shortTextMobile = text.substring(0, 250);

  const trimmedShortText = shortText.substring(
    0,
    Math.min(shortText.length, shortText.lastIndexOf(" "))
  );

  const trimmedShortTextMobile = shortTextMobile.substring(
    0,
    Math.min(shortTextMobile.length, shortTextMobile.lastIndexOf(" "))
  );

  // const nodeRef = useRef(null);

  const toggleFullText = () => {
    setFullText(!FullText);
  };

  const readMore = () => {
    setFullText(true);
  };

  return (
    <>
      <div className="MainTextDesktop">
        <div className="MainText">
          {text.length <= 500 && <p>{text}</p>}
          {text.length > 500 && (
            <>
              <CSSTransition
                // nodeRef={nodeRef}
                in={FullText}
                timeout={500}
                classNames="my-node"
                onEnter={() => setFullText(true)}
                onExited={() => setFullText(false)}
              >
                <p>
                  {FullText ? text : trimmedShortText}
                  <span>{FullText ? "" : "..."}</span>

                  <button className="ToggleFullTextButton" onClick={toggleFullText}>
                    {FullText ? "Condense Text" : "Read More"}
                  </button>
                </p>
              </CSSTransition>
            </>
          )}
        </div>
      </div>
      <div className="MainTextMobile">
        <div className="MainText">
          {text.length <= 250 && <p>{text}</p>}
          {text.length > 250 && (
            <>
              <CSSTransition
                // nodeRef={nodeRef}
                in={FullText}
                timeout={500}
                classNames="my-node"
                onEnter={() => setFullText(true)}
                onExited={() => setFullText(false)}
              >
                <p>
                  {FullText ? text : trimmedShortTextMobile}
                  <span>{FullText ? "" : "..."}</span>
                  {!FullText ? (
                    <button className="ToggleFullTextButton" onClick={readMore}>
                      {"Read More"}
                    </button>
                  ) : null}
                </p>
              </CSSTransition>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default SectionText;
