import React from "react";
import "./CourseCard.css";
import CardButton from "../../Buttons/CardButton/CardButton";
import { Link } from "react-router-dom";
import Icon from "../../../Assets/GGOGIconTM.png";
import FlagsContainer from "../../RatingContainers/FlagsContainer/FlagsContainer";
import FlagIconTransparent from "../../../Assets/FlagIconTransparent.png";
import ComingSoonThumbnail from "../ComingSoonThumbnail.webp";
import "../Cards.css";

const CourseCard = ({
  shortName,
  courseName,
  destinationName,
  thumbnail,
  buttonText,
  rating,
  bucketExperience,
  id,
}) => {
  return (
    <Link to={`/courses/${destinationName}/${courseName}/${id}`}>
      <div className="TravelCard">
        <div className="GGOGIconContainer">
          <img className="GGOGIcon" src={Icon} alt="GGOG Icon"></img>
        </div>
        <div className="CardContentContainer">
          {thumbnail ? (
            <img
              className="TravelCardThumbnailImage"
              src={thumbnail}
              alt={`${shortName} tee times`}
            />
          ) : (
            <img
              className="TravelCardThumbnailImage"
              src={ComingSoonThumbnail}
              alt={`${shortName} tee times`}
            />
          )}
          <div className="CardName" id="CourseName">
            {shortName}
            <div id="CourseRatingHeadline" title="Click on View Rating Descriptions above for more details">
              GGOG
              <img
                id="FlagIconTransparent"
                src={FlagIconTransparent}
                alt="GGOG Flag"
              />
              ™ Rating:
            </div>
            <div className="row" id="FlagsContainer">
              <FlagsContainer rating={rating} bucketExperience={bucketExperience} />
            </div>
          </div>
          <CardButton buttonText={buttonText} />
        </div>
      </div>
    </Link>
  );
};

export default CourseCard;
