import React from "react";
import { useLoaderData } from "react-router-dom";
import SecondaryButton from "../../../../Components/Buttons/SecondaryButton/SecondaryButton";
import BackToTopButton from "../../../../Components/Buttons/ScrollButtons/BackToTopButton/BackToTopButton";
import BackButton from "../../../../Components/Buttons/BackButton/BackButton";
import ThingToDoCard from "../../../../Components/Cards/ThingToDoCard/ThingToDoCard";
import SubscribeForm from "../../../../Components/SubscribeForm/SubscribeForm";

export const ThingsToDoLoader = async ({ params }) => {
  try {
    const [thingsToDo, region] = await Promise.all([
      fetch(`/api/thingstodo/?region=${params.id}`).then((res) => res.json()),
      fetch(`/api/regions/${params.id}`).then((res) => res.json()),
    ]);
    return {
      thingsToDo,
      region,
    };
  } catch (error) {
    console.log("Fetch error:", error);
  }
};
const ThingsToDo = () => {
  const { thingsToDo, region } = useLoaderData();

  return (
    <>
      <div className="NavigationBufferZone"></div>
      <div>
        {region.addThe ? (
          <div className="PageHeadline">
            Things to Do in the {region.regionName}
          </div>
        ) : (
          <div className="PageHeadline">
            Things to Do in {region.regionName}
          </div>
        )}
        <SecondaryButton text="Request Information" link="/starter" />
        <BackButton
          text={`${region.regionName}`}
          link={`/destinations/${region.destinationName}/${region.regionName}/${region._id}`}
        />
        {thingsToDo.length ? (
          <div style={{ marginTop: "20px" }}>
            <div className="row CardContainer">
              {thingsToDo.map((thingToDo) => (
                <div className="col-xs-1 sm-2 md-3 lg-4" key={thingToDo._id}>
                  <ThingToDoCard
                    thingToDoName={thingToDo.thingToDoName}
                    shortName={thingToDo.shortName}
                    thumbnail={thingToDo.thumbnail}
                    destinationName={region.destinationName}
                    regionName={region.regionName}
                    accommodationName={thingToDo.thingToDoName}
                    id={thingToDo._id}
                    buttonText={`Discover ${thingToDo.shortName}`}
                  />
                </div>
              ))}
            </div>
          </div>
        ) : null}
        {thingsToDo.length > 4 ? <BackToTopButton /> : null}
        <SubscribeForm />
      </div>
    </>
  );
};

export default ThingsToDo;
